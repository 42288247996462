import styled from "styled-components";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import {NitroxData, NitroxDiverContentData} from "./data";
import DiverInfo from "../../common/diver-info";
import BtnPayment from "../../common/btn-payment";

const Wrap = styled.div`

`

const Nitrox = () => {
    return (
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={NitroxData}/>
            <DiverInfo info={NitroxDiverContentData}/>
            <BtnPayment />
        </Wrap>
    )
}

export default Nitrox;