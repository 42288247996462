import * as S from "./styles";
import {useCallback, useEffect, useState} from "react";
import {useCalculatorContext} from "../../../pages/expense/CalculatorPage";

const Process = () => {
    const {init, setSchedule} = useCalculatorContext();
    const [isCheckList, setCheckList] = useState<boolean[]>([
        false, false,
    ]);

    const onChange = useCallback((index: number) => {
        init();

        const copy = [...isCheckList].map((v, idx) => index === idx);
        setCheckList(copy);
    }, []);

    useEffect(() => {
        let processType = 'TECHNICAL';
        let packageId = 1;
        if (isCheckList[1]) {
            processType = 'FUNDIVING';
            packageId = 2;
        }

        setSchedule({
            type: processType,
            packageId: packageId,
        }, "process");
    }, [isCheckList]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.ProcessBx>
                    <ul>
                        <S.ProcessItem $isSelect={isCheckList[0]}>
                            <input name="process-choice" id='technical-c' type="radio" onChange={() => onChange(0)}/>
                            <label htmlFor="technical-c">
                                <span>교육 다이빙</span>
                                <img src="/image/img_package_item_00.png" alt=""/>
                            </label>
                        </S.ProcessItem>
                        <S.ProcessItem $isSelect={isCheckList[1]}>
                            <input name="process-choice" id='fun-diving-c' type="radio" onChange={() => onChange(1)}/>
                            <label htmlFor="fun-diving-c">
                                <span>펀 다이빙</span>
                                <img src="/image/img_package_item_05.png" alt=""/>
                            </label>
                        </S.ProcessItem>
                    </ul>
                </S.ProcessBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Process;