import * as S from "./styles";

interface Props{
    title?: string;
}

const PaymentTitle = ({ title }: Props) => {
    return(
        <S.Wrap>
            <S.Inner>
                <dl>
                    <dt>- 교육 등록 -</dt>
                    <dd>
                        <span>{ title }</span>
                    </dd>
                </dl>
            </S.Inner>
        </S.Wrap>
    )
}

export default PaymentTitle;