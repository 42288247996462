import styled from "styled-components";
import TechnicalInfo from "../../../components/recreation/technical-info";

const Wrap = styled.div`
    
`

const TechnicalInfoPage = () => {
    return(
        <Wrap>
            <TechnicalInfo />
        </Wrap>
    )
}

export default TechnicalInfoPage;