import styled from "styled-components";
import media from "../../../lib/media";
import {motion} from "framer-motion";

export const Wrap = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    background-color: #0F0F10;
`

export const Inner = styled(motion.div)`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${media.tablet`
        padding: 80px 20px;
        box-sizing: border-box;
    `};

    ${ media.mobile`
        padding: 40px 20px;
        gap: 50px;
    `}
`

export const ContentBx = styled(motion.div)`
    padding: 30px;
    background-color: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    
    ${ media.mobile`
        padding: 0;
        background-color: transparent;
    ` };
`

export const PriceBx = styled.div`
    position: absolute;
    top: 0;
    right: 60px;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #ffffff;
    padding: 4px 12px;
    background-color: #0F0F10;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    
    ${ media.mobile`
        display: none;
    ` };
`

export const TitleBx = styled.div`
    margin-bottom: 20px;

    dl {
        display: flex;
        flex-direction: column;
        gap: 20px;

        dt {
            font-weight: 700;
            font-size: 30px;
            line-height: 42px;
            color: #C6151B;
            word-break: keep-all;
        }

        dd {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #0F0F10;
            word-break: keep-all;
        }
    }
    
    ${ media.mobile`
        margin-bottom: 30px;
        dl{
            gap: 12px;
            dt{
                font-size: 24px;
                line-height: 33px;
                color: #ffffff;
            }
            dd{
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
            }
        }
    ` };
`

export const ListBx = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    ${media.tablet`
        grid-template-columns: repeat(1, 1fr);
    `};
`

export const ListItem = styled.div`
    padding-top: 58px;
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    background-color: #FFF2F3;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #0F0F10;
        margin-bottom: 24px;
    }

    dl {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;

        dt {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #0F0F10;
            word-break: keep-all;
        }

        dd {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #333333;
            word-break: keep-all;
        }
    }
    
    ${ media.mobile`
        padding: 20px;
        height: auto;
        align-items: flex-start;
        
        h4{
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 8px;
        }
        dl{
            align-items: flex-start;
            dt{
                font-size: 14px;
                line-height: 19px;
            }
            dd{
                
            }
        }
    ` };
`
