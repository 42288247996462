import styled from "styled-components";
import media from "../../../lib/media";
import {motion} from "framer-motion";


export const Wrap = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
`

export const Inner = styled(motion.div)`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 100px 0;

    ${media.tablet`
        box-sizing: border-box;
        padding: 100px 20px;
    `}

    ${ media.mobile`
        padding: 40px 20px;
    ` };
`

export const InfoBx = styled.div`
    margin-bottom: 30px;

    dl {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        dt {
            font-weight: 700;
            font-size: 30px;
            line-height: 42px;
            color: #0F0F10;
        }

        dd {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #0F0F10;
            white-space: pre-wrap;
        }
    }
    
    ${ media.mobile`
        margin-bottom: 20px;
        
        dl{
            gap: 12px;
            dt{
                font-size: 20px;
                line-height: 28px;            
            }
            dd{
                font-size: 14px;
                line-height: 19px;
            }
        }
    ` };
`

export const InfoListBx = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    ${media.tablet`
        grid-template-columns: repeat(1, 1fr);
    `};
`

export const InfoListItem = styled(motion.div)`
    padding: 23px 24px 41px 24px;
    border: 1px solid #CBCBCB;
    border-radius: 20px;
    overflow: hidden;

    h3 {
        font-weight: 700;
        font-size: 30px;
        line-height: 42px;
        color: #0F0F10;
        padding-bottom: 14px;
        border-bottom: 2px solid #C6151B;
        margin-bottom: 20px;
    }
    
    h4{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #0F0F10;
        white-space: pre-wrap;
        margin-bottom: 20px;
    }

    dl {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        dt {
            width: 120px;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #C6151B;
        }

        dd {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #0F0F10;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;

        li {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #0F0F10;
        }
    }
    
    ${ media.mobile`
        padding: 30px 20px;
        h3{
            font-size: 20px;
            line-height: 28px;
            padding-bottom: 10px;
            margin-bottom: 24px;
        }
        
        h4{
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 12px;
        }
        
        dl{
            margin-bottom: 12px;
            
            &:last-child {
                margin-bottom: 0;
            }
            
            dt{
                font-size: 16px;
                line-height: 22px;
                width: 72px;
            }
            dd{
                font-size: 16px;
                line-height: 22px;
            }
        }
        
        ul{
            gap: 12px;
            li{
                font-size: 16px;
                line-height: 22px;
            }
        }
    ` };
`

export const InfoAddButton = styled.div<{ $isAdd: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0F0F10;
    padding: 8px;
    border: 1px solid #999999;
    margin-bottom: 5px;
    border-radius: 8px;
    overflow: hidden;
    
    &:last-child{
        margin-bottom: 0;
    }

    span{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        font-weight: 700;
        font-size: 30px;
        padding-bottom: 4px;
        color: ${ p => p.$isAdd ? '#999999' : '#0F0F10' };
    }
    
    ${ media.mobile`
        gap: 5px;
        font-size: 16px;
        line-height: 22px;
        padding: 5px;
        span{
            font-size: 25px;
        }
    `};
`