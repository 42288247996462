import styled from "styled-components";
import {motion} from "framer-motion";
import media from "../../../lib/media";

export const Wrap = styled(motion.div)`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Inner = styled(motion.div)`
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    ${media.tablet`
        padding: 0 10px;
    `};
`

export const CloseBx = styled.div`
    position: absolute;
    transform: translateY(-100%);
    right: 0;
    top: -5px;

    &:hover {
        button {
            img {
                width: 22px;
                transform: rotate(180deg);
            }
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 100%;

        img {
            width: 20px;
            transition: transform 0.35s, width 0.35s;
        }
    }

    ${media.tablet`
        right: 10px;
    `};
`

export const Container = styled.div`
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
`

export const ContentBx = styled.div`
`

export const Dimmed = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh + 1px);
    z-index: 5;
`