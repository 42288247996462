import styled from "styled-components";
import media from "../../lib/media";
import { motion } from "framer-motion";

export const Wrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
`

export const Inner = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    
    ${ media.tablet`
        padding: 0 20px;
    ` }
`

export const ContentBx = styled(motion.div)`
    position: relative;
    z-index: 100;
    background-color: #ffffff;
    box-sizing: border-box;
    max-width: 1000px;
    max-height: 500px;
    height: auto;
    width: 100%;
    overflow-y: auto;
    overscroll-behavior: contain;
    border-radius: 10px;
    
    ${ media.tablet`
        padding: 0 0;
    ` }
`

export const TitleBx = styled.div`
    padding: 20px 20px;
    background-color: #C6151B;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3{
        font-size: 27px;
        color: #ffffff;
        font-weight: 700;
    }
    
    button{
        line-height: 0;
    }
    
    ${ media.mobile`
        h3{
            font-size: 20px;
        }
    ` };
`

export const Content = styled.div`
    padding: 0 20px;
    box-sizing: border-box;
    ${ media.tablet`
        padding: 0 0;
    ` };
`



export const Dimmed = styled.div`
    background-color: rgba( 0, 0, 0, 0.5 );
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh + 1px);
    z-index: 10;
`
