import Payment from "../../components/payment";

const PaymentPage = () => {
    return(
        <div>
            <Payment />
        </div>
    )
}

export default PaymentPage;