import {InfoListTableType} from "../../types/common";
import {DiverLessonAddContentType, DiverLessonPackageType} from "../../types/recreation";

export const TechnicalTDIData: InfoListTableType[] = [
    {
        title: 'TDI 테크니컬 교육 과정',
        subTitle: ['과정명(TDI)', '소요일정'],
        list: [
            {
                contents: [
                    {description: 'TDI 인트로 투 텍',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 나이트록스',},
                    {description: '최소 1일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 어드밴스 나이트록스',},
                    {description: '최소 2일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 사이드마운트',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI Advanced 사이드마운트',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI DPV',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 감압절차',},
                    {description: '최소 4일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI Extended Range',},
                    {description: '최소 4일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 트라이믹스',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 어드밴스 트라이믹스',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 어드밴스 난파선',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 베이직 블랜더',},
                    {description: '최소 1일',},
                ]
            },
            {
                contents: [
                    {description: 'TDI 어드밴스 블랜더',},
                    {description: '최소 2일',},
                ]
            },
        ],

        messageList: [
            {
                title: '비고',
                message: '테크니컬 교육비 하루 300$\nDPV 렌탈비용 하루 30$\n산소,헬륨비용 별도\n과정 패스후 라이선스 발급비용 50$ 별도\n숙박,식사 별도',
            },
        ]
    },
]

export const TechnicalNAUIData: InfoListTableType[] = [
    {
        title: 'NAUI 테크니컬 교육 과정',
        subTitle: ['과정명(NAUI)', '소요일정'],
        list: [
            {
                contents: [
                    {description: 'NAUI 인트로 투 텍',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 텍 사이드마운트',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 어드밴스드 사이드마운트',},
                    {description: '최소 4일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 테크니컬 감압',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 헬리트록스',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI DPV',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 트라이믹스 1',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 트라이믹스 2',},
                    {description: '최소 5일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 케번',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 케이브 1',},
                    {description: '최소 7일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 케이브 2',},
                    {description: '최소 8일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 케이브 DPV',},
                    {description: '최소 3일',},
                ]
            },
            {
                contents: [
                    {description: 'NAUI 블랜더',},
                    {description: '최소 1일',},
                ]
            },
        ],

        messageList: [
            {
                title: '비고',
                message: '테크니컬 교육비 하루 300$\nDPV 렌탈비용 하루 30$\n산소,헬륨비용 별도\n과정 패스후 라이선스 발급비용 50$ 별도\n숙박,식사 별도',
            },
        ]
    },
]

export const TechnicalPackageData: DiverLessonPackageType[] = [
    {
        title: '테크니컬 펀다이빙 패키지(감압다이빙 이상)',
        price: '1일 $170',
        list: [
            {
                title: '포함사항',
                description: '숙박(2인 1실 사용조건), 공항픽업, 조식/중식, 더블실린더, 사이드실린더, 50% 산소',
                subDescription: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.'
            },
            {
                title: '미포함사항',
                description: '아일랜드 펀 다이빙시 해양공원 입장료 & 씨푸드 바베큐 별도',
            }
        ]
    }
]

export const TechnicalImagesData: string[] = [
    '/image/img_technical_000.jpg',
    '/image/img_technical_001.jpg',
    '/image/img_technical_002.jpg',
    '/image/img_technical_003.jpg',
    '/image/img_technical_004.jpg',
    '/image/img_technical_005.jpg',
]