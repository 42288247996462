import styled from "styled-components";
import media from "../../lib/media";

export const Wrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 50px 0 100px;
    
    ${ media.mobile`
        padding-top: 40px;
    ` };
`

export const Inner = styled.div`
    padding: 20px;
    box-sizing: border-box;
    max-width: 900px;
    width: 100%;
`

export const Container = styled.div`
    box-sizing: border-box;
    border: thick double #000000;
    box-shadow: 15px 15px 3px rgba( 0, 0, 0, 0.1 );
    border-bottom-right-radius: 30px;
    padding: 40px;
    position: relative;
    overflow: hidden;
    
    ${ media.mobile`
        padding: 40px 20px;
    ` };
`

export const HeaderBx = styled.dl`
    text-align: center;
    margin-bottom: 5px;
    h2{
        font-size: 38px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 30px;
    }
    
    dl{
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        dt{
            font-size: 16px;
            font-weight: 600;
            color: #333333;
        }
        dd{
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            display: flex;
            align-items: center;
            &:before{
                content: '';
                display: inline-block;
                width: 3px;
                height: 3px;
                background-color: #333333;
                margin-right: 5px;
            }
        }
    }
`

export const ContentBx = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 60px;
`

export const ContentListBx = styled.div`
    
`

export const ContentTitleBx = styled.dl`
    border-bottom: dashed #666666;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    dt{
        font-size: 19px;
        font-weight: 600;
        text-align: center;
        letter-spacing: -1px;
    }
    dd{
        font-size: 16px;
        font-weight: 600;
    }
`

export const ContentListGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0;
    }
    
    ${ media.mobile`
        grid-template-columns: repeat(1, 1fr);
    ` };
    
    > ul{
        background-color: rgba( 0, 0, 0, 0.02 );
        padding: 15px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
`

export const ContentList = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    column-gap: 50px;
    
    box-sizing: border-box;
    padding: 0 10px;
    
    li{
        dl{
            display: flex;
            justify-content: space-between;
            dt{
                font-size: 14px;
                font-weight: 600;
                color: #222222;
            }
            dd{
                font-size: 14px;
                font-weight: 500;
                color: #222222;
            }
        }
    }
    
    ${ media.tablet`
        display: flex;
        flex-direction: column;
    ` };
`

export const NoticeBx = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    white-space: pre-wrap;
    word-break: keep-all;
    line-height: 18px;
`


export const ContentListTitleBx = styled.dl`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 7px;
    dt{
        font-size: 14px;
        font-weight: 700;
    }
    dd{
        font-size: 14px;
        font-weight: 700;
    }
`

export const ImgBx = styled.div`
    width: 400px;
    height: 400px;
    position: absolute;
    right: -60px;
    top: -60px;
    opacity: 0.05;
    pointer-events: none;
    img{
        width: 100%;
        height: 100%;
        transform: rotate(-45deg);
        filter: grayscale(1);
    }
`

export const Empty = styled.div`
    text-align: center;
    margin-top: 30px;
    span{
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        border-bottom: 1px solid #222222;
    }
`

export const MessageListBx = styled.div`
    padding: 35px 10px 0;
    ul{
        display: flex;
        flex-direction: column;
        li{
            position: relative;
            padding-left: 10px;
            font-size: 14px;
            color: #666666;
            font-weight: 600;
            line-height: 18px;
            word-break: keep-all;
            &:before{
                content: '-';
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
`

export const MessageBx = styled.div`
    font-size: 12px;
    font-weight: 700;
    color: red;
    text-align: right;
    margin-top: 20px;
`

export const TotalPrice = styled.div`
    padding-top: 30px;

    dl {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 20px;
            font-weight: 700;
            border-bottom: 2px solid #000000;
            padding-bottom: 2px;
        }
    }
`

export const ShareBx = styled.div`
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    box-sizing: border-box;
`

export const TextAreaBx = styled.textarea`
    width: 10px;
    height: 10px;
    margin-bottom: 10px;
    opacity: 0;
`