import styled from "styled-components";
import media from "../../../lib/media";
import {motion} from "framer-motion";

export const Wrap = styled.div`
    padding: 100px 0;
    ${media.mobile`
        padding-top: 30px;
    `};
`

export const Inner = styled.div`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding: 0 20px;
    `};
`

export const NoticeContainer = styled.div`

`

export const NoticeHeader = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }
`

export const NoticeBx = styled.div`
    box-sizing: border-box;
    border-top: none;
`

export const NoticeTitle = styled.div`
    border-bottom: 1px solid #cecece;
    margin-bottom: 10px;

    input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 22px;
        border: none;

        &::placeholder {
            color: gray;
        }
    }
`

export const PasswordBx = styled.div`
    margin-top: 20px;

    > dl {
        > dt {
            display: flex;
            justify-content: flex-end;

            input {
                padding: 5px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 600;
                border: none;
                border-bottom: 1px solid #cecece;

                &::placeholder {
                    color: gray;
                }
            }
        }

        > dd {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-top: 50px;
        }
    }
`

export const Popup = styled(motion.div)<{ $isView: boolean }>`
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    z-index: 1000;
    left: 0;
    top: 0;
    overflow: auto;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: ${p => p.$isView ? 'block' : 'none'};
`

export const PopupInner = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% + 1px);
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.tablet`
        display: block;
    `}
`

export const PopupContainer = styled(motion.div)`
    position: relative;
    max-width: 1300px;
    width: 100%;
    height: 800px;
    z-index: 10;
    border-radius: 10px;
    overflow: hidden;

    ${media.tablet`
        max-width: initial;
        padding: 0;
        height: 100%;
        border-radius: 0px;
    `};
`

export const PopupContent = styled(motion.div)`
    max-width: 1300px;
    height: 800px;
    overflow: auto;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    z-index: 10;
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 0 20px;

    ${media.tablet`
        max-width: initial;
        padding: 0;
        height: 100%;
    `};
`

export const PopupClose = styled.div`
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 11;

    button {

    }

    ${media.tablet`
        right: 20px;
        top: 10px;
    `}
`

export const Dimmed = styled(motion.div)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
`

export const OrderDetailBx = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
`

export const OrderDetail = styled.dl`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    dt{
        font-size: 16px;
        font-weight: 600;
    }
    dd{
        font-size: 16px;
        font-weight: 600;
    }
`