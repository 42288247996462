import styled from "styled-components";
import media from "../../../lib/media";
import {motion} from "framer-motion";

export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`

export const Inner = styled(motion.div)`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 80px 0 66px;
    
    h3{
        font-weight: 700;
        font-size: 30px;
        line-height: 42px;
        color: #0F0F10;
        margin-bottom: 20px;
    }
    
    ul{
        display: flex;
        flex-direction: column;
        gap: 8px;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #0F0F10;
        }
    }
    
    ${ media.tablet`
        padding: 80px 20px 66px;
        box-sizing: border-box;
    ` };

    ${ media.mobile`
        padding: 40px 20px;
        ul{
            li{
                align-items: flex-start;
                flex-direction: column;
                
                font-size: 14px;
                line-height: 19px;
            }
        }
    ` };
`

export const ItemBx = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 20px;

    ${ media.tablet`
        gap: 10px;
    ` };
    
    ${ media.mobile`
        gap: 8px;
        grid-template-columns: repeat(2, 1fr);
    ` };
`


export const ItemWrap = styled.div`
    width: 100%;
    aspect-ratio: 1;
    //height: 244px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF2F3;
    border-radius: 32px;
    overflow: hidden;

    dl {
        position: relative;

        dt {
            font-weight: 700;
            font-size: 30px;
            line-height: 42px;
            color: #C6151B;
            white-space: pre-wrap;
            text-align: center;
        }

        dd {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            transform: translateY(100%);
            line-height: 0;
            font-size: 0;
            span{
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #C6151B;
                position: relative;
                display: inline-flex;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
            }
        }
    }
    
    ${media.tablet`
        dl{
            dt{
                font-size: 20px;
                line-height: 28px;
            }
            dd{
                span{
                    font-size: 14px;
                    line-height: 19px;
                }
            }
        }
    `};

    ${ media.mobile`
        aspect-ratio: 2/1;
        dl{
            dt{ 
                text-align: center;
                font-size: 15px; 
                line-height: 18px;
            }
            dd{
                position: relative;
                transform: translateY(0%);
            }
        }
    ` };
`;
