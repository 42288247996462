import styled from "styled-components";
import media from "../../lib/media";

export const Wrap = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
`

export const Inner = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 100px 0;
    
    ${ media.mobile`
        padding: 60px 0 0;
    ` };
`