import PriceContent from "../../../components/price-content";

const PricePage = () => {
    return(
        <div>
            <PriceContent />
        </div>
    )
}

export default PricePage;