import * as S from "./styles";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import Gmap from "./g-map";
import {useRef} from "react";
import SubTitle from "../../common/sub-title";
import TableComponent from "../../table-component";
import {ContactData} from "./data";

const Contact = () => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const render = (status: Status) => {

        if( mapContainerRef.current ){
            switch (status) {
                case Status.LOADING:
                case Status.FAILURE:
                case Status.SUCCESS:
                    return <Gmap container={mapContainerRef.current} />;
            }
        }else{
            return <></>
        }
    };

    return(
        <S.Wrap>
            <S.Inner>
                <SubTitle txt={'오시는 길'}></SubTitle>
                <S.InfoBx>
                    <ul>
                        <li>세부 입국시 필요한 전자입국신고서 작성</li>
                        <li><a href="https://etravel.gov.ph" target="_blank">https://etravel.gov.ph</a></li>
                    </ul>
                </S.InfoBx>
                <S.TableBx>
                    <div>
                        <TableComponent info={ContactData}></TableComponent>
                    </div>
                </S.TableBx>

                <S.InfoBx>
                    <ul>
                        <li>세부 공항 도착후 짐 찾으시고 출구 우측으로 나오시면</li>
                        <li>저희 한국인 강사가 팀맥스 피켓들고 기다리고 있습니다.</li>
                    </ul>
                    <S.InfoImageBx>
                        {/*<span>이래도 못찾을꺼야? 정말?</span>*/}
                        <img src="/image/img_contact_man.jpg" alt=""/>
                    </S.InfoImageBx>
                </S.InfoBx>

                <S.MapBx>
                    <Wrapper
                        apiKey="AIzaSyDTBk9ygjxvVMy-a99bauzRV_bXGY3sFzI"
                        render={render}
                        libraries={['marker']}
                    />

                    <div id='map' style={{height: '50dvh'}} ref={mapContainerRef}></div>
                </S.MapBx>

                <S.ImgBx>
                    <img src="/image/img_contact.jpg" alt=""/>
                </S.ImgBx>

            </S.Inner>
        </S.Wrap>
    )
}

export default Contact;