import {InfoListTableType} from "../../../types/common";

export const ResortImagesData: string[] = [
    '/image/img_resort_000.jpg',
    '/image/img_resort_001.jpg',
    '/image/img_resort_002.jpg',
    '/image/img_resort_003.jpg',
    '/image/img_resort_004.jpg',
    '/image/img_resort_005.jpg',
    '/image/img_resort_006.jpg',
    '/image/img_resort_007.jpg',
    '/image/img_resort_008.jpg',
    '/image/img_resort_009.jpg',
    '/image/img_resort_010.jpg',
]

export const ResortEquipmentData: InfoListTableType[] = [
    {
        title: '',
        subTitle: ['공동비품'],
        list: [
            {
                contents: [
                    {
                        description: '' +
                            '에어컨, 책상, 냉장고, 금고, 헤어드라이어, 수건\n' +
                            '모든 객실 온수 사용 가능\n' +
                            '모든 객실 WIFI 사용 가능\n' +
                            '객실 체크인 시간은 오후 3시, 체크아웃 시간은 오전 12시가 기준입니다.'
                    },
                ]
            },
        ],
    }
]
