import ImgSwiper from "../../img-swiper";
import {ResortImagesData, ResortEquipmentData} from "./data";
import styled from "styled-components";
import TitleBx from "../../common/title-bx";
import TableComponent from "../../table-component";
import media from "../../../lib/media";

const Wrap = styled.div``;
const Inner = styled.div`
    padding: 100px 0 0;
    ${ media.mobile`
        padding: 60px 0 0;
    ` }
`

const Resort = () => {
    return(
        <Wrap>
            <Inner>
                <TitleBx
                    title={'객실안내'}
                    description={'팀맥스 다이브 리조트는 전객실 오션&풀사이드 뷰로 다이버들의 이동을 최소화하고넓은 수영장 주변을 두르고 있으며 매월 2회의 전체 방역을 진행하고 있습니다.\n또한 전객실에 온수 히터와 벽걸이 듀얼 인버터 에어컨이 있어 항상 최상의 컨디션을 유지하고 있습니다.'}
                />

                <ImgSwiper info={ResortImagesData} />
                <TableComponent info={ResortEquipmentData}></TableComponent>
            </Inner>
        </Wrap>
    )
}

export default Resort;