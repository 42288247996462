import * as S from "./styles";
import PaymentTitle from "./common/payment-title";
import UserInfoInput from "./common/user-info-input";
import PaymentBx from "./common/payment-bx";
import ProductInfo from "./common/product-info";
import {createContext, useCallback, useContext, useState} from "react";

import {TechnicalDivingData} from "../recreation/technical-info/data";
import {useParams} from "react-router-dom";
import {InfoListTableListType} from "../../types/common";

function getData(code: string) {
    const data = TechnicalDivingData[0].list.filter((item: InfoListTableListType) => item.technicalCode === code)[0];
    return {
        title: data.contents[0].description,
        price: data.contents[1].price,
        list: data.contents[1].content?.contentList
    }
}

export const PaymentContext = createContext<{
    setInfoData: (info: any) => void,
    paymentData: any,
}>({
    setInfoData: function (info: any) {
    },
    paymentData: {}
});

const Payment = () => {
    /*
        (useParams)
        open-water-thrifty
        open-water
        open-water-fun
        advanced
        open-water-advanced
        nitrox
        rescue
        cpr
        master
     */

    const {code} = useParams();
    const technicalData = getData(code || '');

    const [paymentData, setPaymentData] = useState<any>({});
    const setInfoData = useCallback((info: any) => {
        setPaymentData(info);
    }, []);

    if (!technicalData) return null;

    return (
        <PaymentContext.Provider value={{setInfoData, paymentData}}>
            <S.Wrap>
                <S.Inner>
                    <PaymentTitle title={technicalData.title}/>
                    <UserInfoInput/>
                    <ProductInfo technicalData={technicalData}></ProductInfo>
                    <PaymentBx technicalData={technicalData}></PaymentBx>
                </S.Inner>
            </S.Wrap>
        </PaymentContext.Provider>
    )
}

export const usePaymentContext = () => useContext(PaymentContext);

export default Payment;