import * as S from "./styles";
import TitleBx from "../common/title-bx";
import TableComponent from "../table-component";
import {DivingPriceDatas, FoodPriceDatas, ParkPriceDatas, RentalPriceDatas, ResortPriceDatas} from "./data";

const PriceContent = () => {
    return (
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'숙박&다이빙 비용'}
                    description={'합리적인 가격으로 열대바다의 아름다움과 스쿠버다이빙을 즐기세요.'}
                />

                <TableComponent info={ResortPriceDatas} />
                <TableComponent info={DivingPriceDatas} />
                <TableComponent info={FoodPriceDatas} />
                <TableComponent info={RentalPriceDatas} />
                <TableComponent info={ParkPriceDatas} />
            </S.Inner>
        </S.Wrap>
    )
}

export default PriceContent;