import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 0;
`

export const MapBx = styled.div`
    margin-bottom: 70px;
`

export const ImgBx = styled.div`
    margin-top: 50px;
    img{
        width: 100%;
    }
`

export const InfoBx = styled.div`
    box-sizing: border-box;
    ul{
        display: flex;
        flex-direction: column;
        gap: 15px;
        li{
            font-weight: 700;
            font-size: 22px;
            text-align: center;
            word-break: keep-all;
            a{
                display: block;
                text-decoration: underline;
                color: #000000;
            }
        }
    }

    ${ media.tablet`
        padding: 0 30px;
        ul{
            gap: 6px;
            li{
                font-size: 16px;
                line-height: 18px;
            }
        }
    ` };
`

export const TableBx = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    > div{
        width: 800px;
    }
`

export const InfoImageBx = styled.div`
    margin: 0 auto;
    max-width: 700px;
    position: relative;
    span{
        font-weight: 700;
        font-size: 30px;
        background-color: red;
        color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50px;
        transform: translateX(-50%);
        padding: 10px;
        border-radius: 10px;
    }
    
    img{
        width: 100%;
    }
`