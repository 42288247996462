import {useEffect, useState} from "react";
import * as S from "./styles";
import {GetCalculatorList} from "../../../api/calculator";
import {Link} from "react-router-dom";
import moment from "moment";
import {useInfiniteScroll} from "../../../hooks/useInfiniteScroll";

const CalculatorListPage = () => {
    const [listData, setListData] = useState<any[]>([]);
    const [listNum, setListNum] = useState<number>(0);
    const [limit] = useState<number>(3);

    const infiniteScroll = useInfiniteScroll(setListNum, limit);

    const getData = async (pNum: number) => {
        infiniteScroll.loading(true);

        const data = await GetCalculatorList(pNum);
        const copyData = listData.concat();

        setListData([...copyData, ...data]);

        infiniteScroll.loading(data.length < limit);
    }

    useEffect(() => {
        getData(listNum);
    }, [listNum]);

    useEffect(() => {
        console.log(listNum, listData);
    }, [listNum, listData]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <dt>New</dt>
                    <dd>비용 문의 리스트를 확인해보세요.</dd>
                </S.TitleBx>
                <S.ListContaienr>
                    {
                        listData && <>
                            <S.ListBx>
                                <ul>
                                    {
                                        listData.map((item: any, idx: number) => (
                                            <li key={`notice-${idx}`}>
                                                <Link to={`/expense/complete/${item.orderId}`}>
                                                    <S.Process>{item.type}</S.Process>
                                                    <S.MessageBx>
                                                        {item.message || '문의 드립니다.'}
                                                    </S.MessageBx>

                                                    <S.InfoBx>
                                                        <dt>{item.username || '익명'}</dt>
                                                        <dd>{moment(item.createData).format("YYYY.MM.DD")}</dd>
                                                    </S.InfoBx>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </S.ListBx>
                        </>
                    }
                </S.ListContaienr>
                <S.ScrollArea ref={infiniteScroll.areaRef}></S.ScrollArea>
            </S.Inner>
        </S.Wrap>
    )
}

export default CalculatorListPage;