import * as S from "./styles";
import React from "react";
import BtnDefault from "../../../common/btn-default";

interface Props {
    setPassword: (value: string) => void;
    onClick: () => void;
}

const PopupPassword = ({setPassword, onClick}: Props) => {
    return (
        <S.Wrap>
            <S.Inner>
                <dl>
                    <dt>게시글 비밀번호</dt>
                    <dd>
                        <input
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </dd>
                </dl>

                <BtnDefault title={'확인'} onClick={onClick} />
            </S.Inner>
        </S.Wrap>
    )
}

export default PopupPassword;