import * as S from "./styles";
import {InfoListTableType} from "../../types/common";
import PriceTag from "../price-tag";
import {useInStaggerAction} from "../../hooks/useInStaggerAction";
import {motion} from "framer-motion";
import {ReactNode} from "react";
import {BtnPayment} from "./styles";
import {Link} from "react-router-dom";

const TableContainer = ({children}: { children: ReactNode }) => {
    const {
        ref,
        controls,
        variants,
    } = useInStaggerAction({
        staggerTime: 0.02,
        amount: 0,
    });

    return (
        <motion.div
            style={{'width': '100%'}}
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={variants}
        >
            {children}
        </motion.div>
    )
}

const TableComponent = ({info}: { info: InfoListTableType[] }) => {
    const {
        itemVariants
    } = useInStaggerAction({
        duration: 0.2
    });

    return (
        <S.Wrap
        >
            <S.Inner>
                <S.TableBx>
                    {
                        info.map((item, idx) => (
                            <TableContainer key={`table-component-${idx}`}>
                                <S.TableInner>
                                    <h3>{item.title}</h3>
                                    <table>
                                        <tbody>
                                        <tr>
                                            {
                                                item.subTitle.map((t, tIdx) => (
                                                    <th key={`table-component-${idx}-${tIdx}`}>
                                                        {t}
                                                    </th>
                                                ))
                                            }
                                        </tr>

                                        {
                                            item.list.map((con, conIdx) => (
                                                <tr key={`table-component-content-${idx}-${conIdx}`}>
                                                    {
                                                        con.contents.map((v, vIdx) => (
                                                            <td key={`table-component-tr-${idx}-${vIdx}`}>
                                                                <S.ContentBx variants={itemVariants}>
                                                                    {
                                                                        v.price && <PriceTag price={v.price}></PriceTag>
                                                                    }

                                                                    {
                                                                        v.description && <S.DescriptionBx>
                                                                            {v.description}
                                                                            {
                                                                                v.addPrice &&
                                                                                <PriceTag price={v.addPrice}></PriceTag>
                                                                            }
                                                                        </S.DescriptionBx>
                                                                    }

                                                                    {
                                                                        v.content && <S.ContentList>
                                                                            <h4>{v.content.title}</h4>
                                                                            <ul>
                                                                                {
                                                                                    v.content.contentList.map((value, valueIdx) => (
                                                                                        <li key={`value-${idx}-${valueIdx}`}>
                                                                                            {value.title}
                                                                                            <span>{value.description}</span>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        </S.ContentList>
                                                                    }

                                                                    <S.ContentDescription>
                                                                        {
                                                                            v.descriptionList?.map((d, dIdx) => (
                                                                                <li key={`d-${idx}-${dIdx}`}>{d}</li>
                                                                            ))
                                                                        }
                                                                    </S.ContentDescription>

                                                                    {/*{*/}
                                                                    {/*    v.link && <BtnPayment>*/}
                                                                    {/*        <Link to={v.link}>결제 신청하기</Link>*/}
                                                                    {/*    </BtnPayment>*/}
                                                                    {/*}*/}
                                                                </S.ContentBx>
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }

                                        </tbody>
                                    </table>
                                    {
                                        item.messageList && <S.MessageBx>
                                            {
                                                item.messageList.map((m, mIdx) => (
                                                    <S.MessageList
                                                        key={`message-${mIdx}`}
                                                        variants={itemVariants}
                                                    >
                                                        <dt>{m.title}</dt>
                                                        <dd>{m.message}</dd>
                                                    </S.MessageList>
                                                ))
                                            }
                                        </S.MessageBx>
                                    }
                                </S.TableInner>
                            </TableContainer>
                        ))
                    }
                </S.TableBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default TableComponent;