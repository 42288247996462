import {DiveAboutItemsType} from "../../../../types/about";
import * as S from "./styles";

interface Props {
    item: DiveAboutItemsType
}

const DiveAboutItem = ({item}: Props) => {
    return (
        <S.ItemWrap>
            <S.ItemInner>
                <dl>
                    <dt>{item.title}</dt>
                    <dd>{item.description}</dd>
                </dl>

                {
                    item.src && <S.ImgBx>
                        <img src={item.src} alt=""/>
                    </S.ImgBx>
                }
            </S.ItemInner>
        </S.ItemWrap>
    )
}

export default DiveAboutItem;