import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    background-color: #ffffff;
    border-top: 1px solid rgba( 0, 0, 0, 0.2 );
    display: flex;
    justify-content: center;
    align-items: center;
    
    ${ media.tablet`
        padding: 10px 10px;
        border-top: none;
    ` };
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    
    dl{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;
        dt{
            font-size: 20px;
            font-weight: 700;
            color: #0F0F10;
        }
        dd{
            font-size: 24px;
            font-weight: 700;
            color: #0F0F10;
        }
    }
    
    button{
        font-size: 28px;
        font-weight: 700;
        color: #ffffff;
        background-color: #C6151B;
        padding: 15px 100px;
        border-radius: 10px;
    }
    
    ${ media.tablet`
        dl{
            display: none;
            dt{
            
            }
            dd{
            
            }
        }
        
        button{
            width: 100%;
            padding: 13px 0px;
            font-size: 20px;
            border-radius: 5px;
        }
    ` };
`