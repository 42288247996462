import {useEffect} from "react";
import axios from "axios";

const My = () => {

    // console.log(localStorage.getItem('kakao_access_token'));

    const getProfile = async () => {
        try {
            if (window) {
                const {Kakao} = window as any;

                // const data = await Kakao.API.request({
                //     url: '/v2/user/me',
                // });

                console.log(localStorage.getItem('kakao_a26a48a77027126996253cbaad6133d9'));

                const data = await axios.get('https://kapi.kakao.com/v2/user/me', {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
                        Authorization: `Bearer ${localStorage.getItem('kakao_access_token')}`,
                    }
                });

                console.log(data);
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getProfile();
    }, []);

    return (
        <div>
            <div>111</div>
        </div>
    )
}

export default My;