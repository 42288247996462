import {InfoListTableType} from "../../types/common";

export const InstructorNAUIData: InfoListTableType[] = [
    {
        title: 'NAUI',
        subTitle: ['구분', '기간', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '다이브 마스터 과정 또는\n보조강사',
                    },
                    {
                        description: '최소 5일',
                    },
                    {
                        price: '$1,500',
                        description: '(NAUI 강사 과정 사전 조건)',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '강사 교육',
                    },
                    {
                        description: '최소 10일',
                    },
                    {
                        price: '$3,000',
                        description: '(교육 8일 평가 2일)',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'NAUI/DAN FIRST AID &\nCPR 강사',
                    },
                    {
                        description: '최소 2일',
                    },
                    {
                        price: '$700',
                        description: '(응급처치 강사과정)',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'NAUI 스페셜티 강사',
                    },
                    {
                        description: '별도 문의',
                    },
                    {
                        price: '',
                        description: '',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'NAUI TECHNICAL 강사',
                    },
                    {
                        description: '별도 문의',
                    },
                    {
                        price: '',
                        description: '',
                    }
                ]
            },
        ],
        messageList: [
            {
                title: '',
                message: '각 과정당 서류 or 패키지 등록비용 별도\n체류비 별도',
            },
        ]
    },
]
export const InstructorSDIData: InfoListTableType[] = [
    {
        title: 'SDI',
        subTitle: ['구분', '기간', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '다이브 마스터 과정',
                    },
                    {
                        description: '최소 5일',
                    },
                    {
                        price: '$1,500',
                        description: '(SDI 강사 과정 사전 조건)',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '강사 교육',
                    },
                    {
                        description: '최소 10일',
                    },
                    {
                        price: '$3,000',
                        description: '(교육 8일 평가 2일)',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'FRTI 강사',
                    },
                    {
                        description: '최소 2일',
                    },
                    {
                        price: '$700',
                        description: '(응급처치 강사과정)',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'SDI 스페셜티 강사',
                    },
                    {
                        description: '별도 문의',
                    },
                    {
                        price: '',
                        description: '',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'TDI 강사',
                    },
                    {
                        description: '별도 문의',
                    },
                    {
                        price: '',
                        description: '',
                    }
                ]
            },
        ],
        messageList: [
            {
                title: '',
                message: '각 과정당 서류 or 패키지 등록비용 별도\n체류비 별도',
            },
        ]
    },
]