import qs from "qs";
import {useEffect, useState} from "react";
import axios from "axios";

export const REST_API_KEY = 'b5bd33e4852e5aa392e52518cf94b9cb';
export const REDIRECT_URI = 'http://localhost:3000/login';
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
export const CLIENT_SECRET = '6KdE4jlEJdtPSDPGwl9NKwuYRMbvMOfy';

const Login = () => {
    const params = new URL(document.URL).searchParams;
    const code = params.get('code');
    const [isLogin, setLogin] = useState<boolean>(false);

    const getToken = async () => {
        if (window) {
            if ( isLogin ){
                console.log( "Loading Complete" );
                return;
            }

            console.log( "몇번을 호출하는거야?", code );

            const {Kakao} = window as any;

            const payload = qs.stringify({
                grant_type: 'authorization_code',
                client_id: REST_API_KEY,
                redirect_uri: REDIRECT_URI,
                code: code,
                client_secret: CLIENT_SECRET,
            });

            try {
                const res = await axios.post('https://kauth.kakao.com/oauth/token', payload, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                });

                Kakao.init(REST_API_KEY); // Kakao Javascript SDK 초기화
                Kakao.Auth.setAccessToken(res.data.access_token); // access token 설정

                localStorage.setItem('kakao_access_token', res.data.access_token);

                setLogin( true );
                // navigate('/my');

            } catch (err) {
                console.log( "-------------- ERROR ---------------" );
                console.log(err);

                setLogin( true );
            }
        }
    }

    useEffect(() => {
        if( code ){
            getToken();
        }
    }, []);

    return (
        <div>
            <div>
                <a href={KAKAO_AUTH_URL}>카카오 로그인</a>

                <div>
                    {code}
                </div>
            </div>
        </div>
    )
}

export default Login;