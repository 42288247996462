import styled from "styled-components";


export const Wrap = styled.div`
    
`

export const Inner = styled.div`
    padding-top: 50px;
`

export const ListBx = styled.div`
    dl {
        border-top: 1px solid #f2f2f2;
        box-sizing: border-box;
        padding: 20px;

        dt {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            white-space: pre-wrap;
        }

        dd {
            font-size: 14px;
            font-weight: 500;
            color: #979797;
            margin-top: 10px;
        }
    }
`

export const ReplyBx = styled.div`
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #cecece;
    margin-top: 30px;

    h3 {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 10px;
    }

    textarea {
        width: 100%;
        border: none;
        height: 50px;
        resize: none;
        outline: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
`

export const Btn = styled.div`
    display: flex;
    justify-content: flex-end;
    button{
        font-size: 14px;
        font-weight: 700;
        color: #000000;
        border-bottom: 1px solid #000000;
    }
`

