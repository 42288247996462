import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    min-height: 100dvh;
`

export const Inner = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    height: auto;
    box-sizing: border-box;
    padding: 75px 20px;
    
    ${ media.mobile`
        padding-top: 40px;
    ` };
`

export const TitleBx = styled.dl`
    text-align: left;
    margin-bottom: 110px;

    dt {
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    dd {
        font-size: 20px;
        font-weight: 600;
    }
    
    ${ media.mobile`
        margin-bottom: 50px;
        dt{
            font-size:42px;
            margin-bottom: 15px;
        }
        dd{
            font-size: 16px;
        }
    ` };
`

export const ListContaienr = styled.div`
    width: 100%;
`

export const ListBx = styled.div`
    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        li {
            a {
                position: relative;
                display: block;
                border: 1px solid #dddddd;
                border-radius: 15px;
                padding: 20px;
                box-sizing: border-box;
                transition: background-color 0.25s, transform 0.25s, border 0.25s, box-shadow 0.25s;

                &:hover {
                    transform: translateY(-5px);
                    border: 1px solid #999999;
                    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    ${ media.tablet`
        ul{
            grid-template-columns: repeat(2, 1fr);
    ` };

    ${media.mobile`
        ul{
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
        }
    `};
`

export const Process = styled.div`
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #111111;
`

export const MessageBx = styled.div`
    font-size: 14px;
    line-height: 23px;
    font-weight: 600;
    height: 95px;
    overflow: hidden;
    margin-bottom: 30px;
    color: #666666;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;
    word-break: keep-all;
`

export const InfoBx = styled.dl`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 7px;

    dt {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
    }

    dd {
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        color: #666666;

        &:before {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            background-color: #999999;
            border-radius: 50%;
        }
    }
`

export const ListControlBx = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 75px;
    
    > button{
        position: relative;
        top: 1px;
        font-size: 14px;
        font-weight: 500;
    }
    
    ul{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        li{
            button{
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 12px;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
`

export const ScrollArea = styled.div`
    width: 100%;
    height: 50px;
    background-color: rgba( 0, 0, 0, 0.5 );
    opacity: 0;
`