import FunDiving from "../../components/fundiving";

const FunDivingPage = () => {
    return(
        <div>
            <FunDiving />
        </div>
    )
}

export default FunDivingPage;