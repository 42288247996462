import OpenwaterFun from "../../../components/recreation/openwater-fun";

const OpenWaterFunPage = () => {
    return(
        <div>
            <OpenwaterFun></OpenwaterFun>
        </div>
    )
}

export default OpenWaterFunPage;