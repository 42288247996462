import styled from "styled-components";
import media from "../../../lib/media";
import {motion} from "framer-motion";


export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0;
    
    ${ media.tablet`
        padding: 100px 0px;
        box-sizing: border-box;
    ` };

    ${ media.mobile`
        padding: 40px 0px;
    ` };
`

export const ListBx = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    box-sizing: border-box;

    ${ media.tablet`
        padding: 0 20px;
        grid-template-columns: repeat(2, 1fr);
    ` };
    
    ${ media.mobile`
        grid-template-columns: repeat(1, 1fr);
    ` };
`

export const ImgBx = styled.div`
    display: inline-block;
    width: 100%;
    height: 188px;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
    
    &:hover{
        img{
            transform: scale(1.15);
        }
    }
    
    img{
        width: 100%;
        transition: transform 0.2s;
    }
`

export const ItemWrap = styled(motion.div)`
    
    dl{
        display: flex;
        flex-direction: column;
        gap: 8px;
        
        dt{
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #0F0F10;
        }
        dd{
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #0F0F10;
            opacity: 0.7;
        }
    }
    
    ${ media.tablet`
        img{
            height: 194px;
        }
    ` };
    
    ${ media.mobile`
        img{
            margin-bottom: 12px;
        }
        
        dl{
            gap: 12px;
            dt{
                font-size: 20px;
                line-height: 28px;
            }
            dd{
                font-size: 14px;
                line-height: 19px;
            }
        }
    ` };
`