import {useEffect, useRef} from "react";
import {useAnimation, useInView} from "framer-motion";

export function useInAction() {
    const variants = {
        hidden: {
            opacity: 0,
            y: 30,
        },

        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.3,
                ease: 'easeOut',
            }
        },
    }

    const ref = useRef(null);
    const isInView = useInView(ref, {
        once: true,
        amount: 0.5,
    });

    const controls = useAnimation();

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [isInView]);

    return {
        ref,
        controls,
        variants
    }
}