import * as S from "./styles";
import TitleBx from "../../common/title-bx";
import TableComponent from "../../table-component";
import {TechnicalDivingData} from "./data";
const TechnicalInfo = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'교육 다이빙'}
                    description={'즐거운 바다로, 안전하고 편안하게 안내해 드리겠습니다.'}
                />

                <TableComponent info={TechnicalDivingData}></TableComponent>
            </S.Inner>
        </S.Wrap>
    )
}

export default TechnicalInfo;