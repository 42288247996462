import React, {useEffect, useState} from "react";
import * as S from "./styles";
import moment from "moment/moment";
import {motion, AnimatePresence} from "framer-motion";
import {useNavigate} from "react-router-dom";
import BtnDefault from "../../common/btn-default";
import {useSearchParams} from "react-router-dom";
import PopupPassword from "./popup-password";
import {getNoticeContent} from "../../../api/notice";
import {Link} from "react-router-dom";
import Reply from "./reply";

const NoticeContent = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [password, setPassword] = useState<string>('');
    const [noticeData, setNoticeData] = useState<any>(null);

    const onClick = async () => {
        let id = params.get('id');
        if (id) {
            if (password === '') {
                alert("패스워드를 입력해주세요");
                return;
            }

            const data = await getNoticeContent(+id, password);
            if (data.isNotPassword) {
                alert("비밀번호가 일치하지 않습니다.");
                navigate("/notice/list");
            } else {
                setNoticeData(data);
            }

        } else {
            navigate("/notice/list");
        }
    }

    useEffect(() => {
        console.log(noticeData);
    }, [noticeData]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.NoticeContainer>
                    <S.NoticeHeader>
                        <dl>
                            <dt>문의 내용</dt>
                        </dl>
                    </S.NoticeHeader>
                </S.NoticeContainer>

                <AnimatePresence mode={'wait'}>
                    {
                        noticeData ? (
                            <motion.div
                                key={noticeData.id}
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 10, opacity: 0}}
                                transition={{
                                    duration: 0.3,
                                    ease: 'easeOut'
                                }}
                            >
                                <S.NoticeBx>
                                    <S.NoticeTitle>
                                        <span>{noticeData.title}</span>
                                        <span>{moment(noticeData.createDate).format("YYYY/MM/DD")}</span>
                                    </S.NoticeTitle>
                                    <S.NoticeContent dangerouslySetInnerHTML={{__html: noticeData.content}}/>
                                    {
                                        noticeData.orderId && <S.OrderLinkBx>
                                            <Link to={`/expense/complete/${noticeData.orderId}`} target={"_blank"}>
                                                온라인 견적 확인
                                            </Link>
                                        </S.OrderLinkBx>
                                    }
                                </S.NoticeBx>

                                <Reply noticeId={noticeData.id}></Reply>

                                <S.BtnBx>
                                    <BtnDefault link={"/notice/list"} title={'확인'}/>
                                </S.BtnBx>
                            </motion.div>
                        ) : (
                            <motion.div
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: -10, opacity: 0}}
                                transition={{
                                    duration: 0.3,
                                    ease: 'easeOut'
                                }}
                            >
                                <PopupPassword setPassword={setPassword} onClick={onClick}></PopupPassword>
                            </motion.div>
                        )
                    }
                </AnimatePresence>
            </S.Inner>
        </S.Wrap>
    )
}

export default NoticeContent;