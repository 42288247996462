import * as S from "./styles";
import {useCallback, useEffect, useState} from "react";
import {useCalculatorContext} from "../../../pages/expense/CalculatorPage";

const MessageComponent = () => {
    const { setSchedule } = useCalculatorContext();

    const [userName, setName] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const onChangeUserName = useCallback((value: string) => {
        setName(value);
    }, []);

    const onChangeMessage = useCallback((message: string) => {
        setMessage(message);
    }, []);

    useEffect(() => {
        const info = {
            userName: userName,
            message: message
        }

        setSchedule(info, "info");
    }, [userName, message]);

    return(
        <S.Wrap>
            <S.Inner>
                <S.InputBx>
                    <input type="text" placeholder={'이름'} onChange={(e) => onChangeUserName(e.target.value)}/>
                </S.InputBx>

                <S.MessageBx>
                    <textarea name="" id="" placeholder={'문의 사항'} onChange={(e) => onChangeMessage(e.target.value)}></textarea>
                </S.MessageBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default MessageComponent;