import axios from "axios";
import {api} from "./apiLink";
import noticeItems from "../components/main/notice/notice-items";

export const NoticeSend = async (
    noticeData: {},
    calculatorData: {},
) => {
    const {data} = await axios.post(api + "/notice/send", {
        noticeData,
        data: calculatorData
    });

    return data;
}

export const getNoticeList = async () => {
    const {data} = await axios.get(api + "/notice/notice-list");
    return data;
}

export const getNoticeContent = async (id: number, password: string) => {
    const {data} = await axios.get(api + "/notice/content", {
        params: {
            id,
            password
        }
    });

    return data;
}

export const setNoticeReply = async (noticeId: number, value: string) => {
    const {data} = await axios.post(api + "/notice/reply", {
        noticeId,
        value
    });

    return data;
}

export const getNoticeReplyList = async (noticeId: number) => {
    const {data} = await axios.get(api + "/notice/reply-list", {
        params: {
            noticeId,
        }
    });

    return data;
}