import {TeamMaxStoreType} from "../../../types/main";


export const TeamMaxStoreData: TeamMaxStoreType[] = [
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
    {
        link: '',
        src: '/image/img_package_item_00.png',
        title: '사이드 마운트 교육',
        date: '2020 / 01 / 10',
    },
]