import * as S from "./styles";
import {PackageItemType} from "../../../types/main";
import {Link} from "react-router-dom";
import {useInAction} from "../../../hooks/useInAction";
import {getComma} from "../../../lib/comma";

const PackageItem = ({item}: PackageItemType) => {
    const {ref, controls, variants} = useInAction();
    return (
        <>
            <Link to={item.link}>
                <S.ItemWrap
                    ref={ref}
                    initial={"hidden"}
                    animate={controls}
                    variants={variants}
                >
                    <S.ItemPrice>
                        <span>${getComma(item.price)}</span>
                    </S.ItemPrice>
                    <S.ItemImageBx>
                        <img src={item.src} alt=""/>
                    </S.ItemImageBx>
                    <S.ItemInfo>
                        <dl>
                            <dt>{item.title}</dt>
                            <dd>{item.description}</dd>
                        </dl>
                        <S.ItemInfoBg>
                            <S.ItemInfoBgFilter $type={item.type}></S.ItemInfoBgFilter>
                            <img src={item.src} alt=""/>
                        </S.ItemInfoBg>
                    </S.ItemInfo>
                </S.ItemWrap>
            </Link>
        </>
    )
}

export default PackageItem;