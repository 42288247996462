import {DiverLessonAddContentType, DiverLessonType} from "../../../types/recreation";

export const NitroxData: DiverLessonType = {
    title: '이 과정에서는 ...',
    description: '나이트록스도 공기와 같은 기체들로 구성되어 있지만(일반탱크 : 질소 79%, 산소 21%) 산소비율이 21%보다 높을때의 기체를\n나이트록스라는 뜻 으로 불리우며 나이트록스 아론과 실기를 같이 배우는 중급자 단계 입니다.',
    infoList: [
        {
            type: 'TABLE',
            title: '과정 참가자격 및 준비물',
            infoType: [
                {
                    title: '참가자격',
                    description: '오픈워터 자격증 소지자',
                },
                {
                    title: '소요시간',
                    description: '1일',
                },
                {
                    title: '준비물',
                    description: '수영복, 개인세면도구, 증명사진 1부',
                },
                {
                    title: '비용',
                    description: '$300',
                }],
        },
        {
            type: 'LIST',
            title: '교육 절차',
            infoList: [
                {
                    description: '국제해양대기국 NOAA 기준 32%',
                },
                {
                    description: '국제해양대기국 NOAA 기준 36%',
                },
                {
                    description: '32%, 36%의 나이트록스 기체를 마시며 다이빙',
                },
                {
                    description: '나이트록스 기체를 사용한 총 2회 다이빙',
                }],
        }],
}

export const NitroxDiverContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '점심식사',
            description: ''
        },
        {
            title: '장비렌탈',
            description: ''
        },
        {
            title: '나이트록스\n이론강의\n32% 36%',
            description: '(2회 다이빙)'
        },
    ],
    referenceList: [
        {
            title: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.',
        },
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
                link: '/',
            }
        }
    ]
}