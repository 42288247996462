import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {useCallback, useEffect, useState} from "react";
import moment from "moment";
import * as S from "./styles";
import BtnDefault from "../../common/btn-default";
import {useCalculatorContext} from "../../../pages/expense/CalculatorPage";

function getDateFormat(date: Date) {
    return moment(date).format("YYYY/MM/DD")
}

interface Props {
    type: string;
}

const CalendarBx = ({type}: Props) => {
    const [isCalendar, setCalendar] = useState<boolean>(false);

    const [sDay, setStart] = useState<Date>();
    const [eDay, setEnd] = useState<Date>();

    const {setSchedule} = useCalculatorContext();

    const changeDate = (e: any) => {
        setStart(e[0] || e);
        setEnd(e[1] || null);

        setCalendar(false);
    }

    const onClickCalendar = useCallback(() => {
        setCalendar(true);

        setStart(undefined);
        setEnd(undefined);

    }, [isCalendar]);


    useEffect(() => {
        if (sDay) {
            if (eDay) {
                const diffTime = +new Date(eDay) - +new Date(sDay);
                const day = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                setSchedule({
                    day: day,
                    startDay: getDateFormat(sDay),
                    endDay: getDateFormat(eDay),
                }, "schedule");
            } else {
                setSchedule({
                    startDay: getDateFormat(sDay)
                }, "schedule");
            }

        }

    }, [sDay, eDay]);

    return (
        <S.Wrap>
            <S.BtnDate>
                <BtnDefault onClick={onClickCalendar} title={'달력'}></BtnDefault>
            </S.BtnDate>

            {
                isCalendar && <S.CalendarContainer>
                    <Calendar
                        onChange={changeDate}
                        value={sDay}
                        formatDay={(locale, date) => moment(date).format("DD")}
                        selectRange={type === 'FUNDIVING'}
                        minDate={moment().toDate()}
                        calendarType={'gregory'}
                    />
                </S.CalendarContainer>
            }

            <S.RangeContainer onClick={onClickCalendar}>
                <S.RangeBx>
                    <span>시작 날짜</span>
                    {
                        sDay && getDateFormat(sDay)
                    }
                </S.RangeBx>
                {
                    type !== "TECHNICAL" && <>
                        <span>~</span>
                        <S.RangeBx>
                            <span>종료 날짜</span>
                            {
                                eDay && getDateFormat(eDay)
                            }
                        </S.RangeBx>
                    </>
                }

            </S.RangeContainer>
        </S.Wrap>
    )
}

export default CalendarBx;