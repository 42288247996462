import {Link} from "react-router-dom";
import * as S from "./styles";

const Footer = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <S.MenuBx>
                    <h3>TEAM MAX DIVE RESORT</h3>
                    <ul>
                        <li><Link to={"/team-max/about"}>팀맥스 소개</Link></li>
                        <li><Link to={"/team-max/contact"}>찾아오시는 길</Link></li>
                        <li><Link to={"/price"}>가격안내</Link></li>
                        <li><Link to={"/"}>공지사항</Link></li>
                    </ul>
                </S.MenuBx>
                <S.InfoBx>
                    <S.FooterInfo>
                        <dl>
                            <dt>법인명 : </dt>
                            <dd>TEAM MAX OCEAN</dd>
                        </dl>

                        <dl>
                            <dt>법인 허가번호 : </dt>
                            <dd>01806283</dd>
                        </dl>

                        <dl>
                            <dt>대표 :</dt>
                            <dd>이우권</dd>
                        </dl>
                        <dl>
                            <dt>팀맥스 주소 :</dt>
                            <dd>#6015 Team Max Dive Resorts Marbach Marigondon LapuLapu Cebu Philippines.</dd>
                        </dl>
                        <dl>
                            <dt>E-mail :</dt>
                            <dd>
                                <a href="mailto:teammaxdive@naver.com ">teammaxdive@naver.com </a>
                            </dd>
                        </dl>
                    </S.FooterInfo>
                    <S.Copyright>Copyright © TEAM MAX OCEAN. All Rights Reserved.</S.Copyright>
                </S.InfoBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Footer;