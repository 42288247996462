import {TeamMaxStoreType} from "../../../types/main";
import * as S from "./styles";
import {Link} from "react-router-dom";
import {useInAction} from "../../../hooks/useInAction";

interface Props {
    item: TeamMaxStoreType
}

const StoreItem = ({item}: Props) => {
    const { ref, controls, variants } = useInAction();

    return (
        <Link to={item.link} ref={ref}>
            <S.ItemWrap
                initial={"hidden"}
                animate={controls}
                variants={variants}
            >
                <S.ImgBx>
                    <img src={item.src} alt=""/>
                </S.ImgBx>
                <dl>
                <dt>{item.title}</dt>
                    <dd>{item.date}</dd>
                </dl>
            </S.ItemWrap>
        </Link>
    )
}

export default StoreItem;