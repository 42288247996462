import * as S from "./styles";
import React, {useCallback, useEffect, useState} from "react";
import BtnDefault from "../../common/btn-default";
import {NoticeSend} from "../../../api/notice";
import {NoticeSendType} from "../../../types/notice";
import {useNavigate} from "react-router-dom";
import QuillEditor from "./quill-editor";
import CalculatorPopup from "./calculator-popup";
import {AnimatePresence} from "framer-motion";

const NoticeWrite = () => {
    const navigator = useNavigate();
    const [sendData, setSendData] = useState<NoticeSendType>({
        title: '예약 문의드립니다.',
        content: '',
        password: '',
    });
    const [calculatorData, setCalculatorData] = useState<any>(null);

    const onChange = <K extends keyof NoticeSendType>(value: string, key: K) => {
        console.log("----------- onChange ----------------");
        console.log(sendData);

        setSendData({
            ...sendData,
            [key]: value
        });
    }

    const send = async () => {
        if (sendData.title === '') {
            alert("제목을 입력해주세요.")
            return;
        }

        if (sendData.password === '') {
            alert("비밀번호를 입력해주세요.")
            return;
        }

        console.log(sendData);

        await NoticeSend(sendData, calculatorData);

        alert("등록되었습니다." );
        navigator("/notice/list");
    }

    useEffect(() => {
        console.log(sendData);
    }, [sendData]);

    const [isPopup, setPopup] = useState<boolean>(false);
    const onClickEstimate = useCallback(() => {
        setPopup((boo) => !boo);
    }, []);


    const onCompleteCalculator = (data: any) => {
        console.log("-------- Calculator Data Complete -------");
        console.log(data);

        setCalculatorData(data);
        setPopup(false);
    }

    return (
        <S.Wrap>
            <S.Inner>
                <S.NoticeContainer>
                    <S.NoticeHeader>
                        <dl>
                            <dt>문의하기</dt>
                            <dd>양식에 맞게 작성해 주시길 바랍니다.</dd>
                        </dl>
                    </S.NoticeHeader>

                    <S.NoticeBx>
                        <S.NoticeTitle>
                            <input
                                placeholder={'제목을 입력해주세요.'}
                                type="text"
                                value={sendData.title}
                                onChange={(e) => onChange(e.target.value, "title")}
                            />
                        </S.NoticeTitle>
                        <QuillEditor onChangeText={onChange}/>
                    </S.NoticeBx>

                    <S.PasswordBx>
                        <dl>
                            <dt>
                                <input
                                    placeholder={'비밀번호'}
                                    type="password"
                                    onChange={(e) => onChange(e.target.value, "password")}
                                    defaultValue={sendData.password}
                                />
                            </dt>
                            <dd>
                                <S.OrderDetailBx>
                                    <BtnDefault title={'온라인 견적 첨부'} onClick={onClickEstimate}></BtnDefault>
                                    {
                                        calculatorData && calculatorData.process && <S.OrderDetail>
                                            <dt>
                                                첨부 파일 :
                                            </dt>
                                            <dd>({calculatorData.process.type === "FUNDIVING" ? "펀다이빙" : "교육다이빙"})</dd>
                                        </S.OrderDetail>
                                    }
                                </S.OrderDetailBx>
                                <BtnDefault title={'등록하기'} onClick={send}></BtnDefault>
                            </dd>
                        </dl>
                    </S.PasswordBx>
                </S.NoticeContainer>

                <AnimatePresence>
                    {
                        <S.Popup $isView={isPopup}>
                            <S.PopupInner>
                                <S.PopupContainer
                                    initial={{opacity: 0, scale: 0.98}}
                                    animate={{opacity: 1, scale: 1}}
                                    exit={{opacity: 0, scale: 0.98}}
                                    transition={{duration: 0.2, ease: 'easeOut'}}
                                >
                                    <S.PopupContent>
                                        <CalculatorPopup onComplete={onCompleteCalculator}/>
                                    </S.PopupContent>

                                    <S.PopupClose>
                                        <button onClick={() => setPopup(false)}>
                                            <img src="/icon/ic_btn_close.svg" alt=""/>
                                        </button>
                                    </S.PopupClose>
                                </S.PopupContainer>

                                <S.Dimmed
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    transition={{duration: 0.2, ease: 'easeOut'}}
                                    onClick={() => setPopup(false)}
                                />
                            </S.PopupInner>
                        </S.Popup>
                    }
                </AnimatePresence>
            </S.Inner>
        </S.Wrap>
    )
}

export default NoticeWrite;