import {DiveAboutData} from "./data";
import DiveAboutItem from "./dive-about-item";
import * as S from "./styles";

const DiveAbout = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <S.Title>
                    필리핀 세부 막탄섬에 위치한 팀맥스 다이브 리조트는 바다와 맞닿은 환경과 다이빙,<br /> 숙박,식사등 모든것이 가능한 일체형 다이빙 리조트 입니다.<br />
                    전객실이 오션&풀사이드 뷰 이며 25M 수영장을 둘러싸고 있습니다.<br />
                    펀다이빙과 다이빙 교육은 물론이고 여행과 힐링 그 모든것이 가능한 최고의 다이빙 리조트 입니다
                </S.Title>
                <S.ItemBx>
                    {
                        DiveAboutData.map((item, idx) => (
                            <DiveAboutItem
                                key={`dive-about-${idx}`}
                                item={item}
                            />
                        ))
                    }
                </S.ItemBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default DiveAbout;