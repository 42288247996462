import * as S from "./styles";
import {useCalculatorContext} from "../../pages/expense/CalculatorPage";
import Process from "./process";
import FunDivingBx from "./fun-diving-bx";
import TechnicalBx from "./technical-bx";

const Calculator = () => {
    const {data} = useCalculatorContext();

    return (
        <S.Wrap>
            <S.Inner>
                <S.Bx>
                    <S.ContentBx $isView={true}>
                        <h3>1. 선택</h3>
                        <Process/>
                    </S.ContentBx>
                    {
                        data.process && <>
                            {
                                data.process.type === 'TECHNICAL' ? <TechnicalBx/> : <FunDivingBx/>
                            }
                        </>
                    }
                </S.Bx>
            </S.Inner>
        </S.Wrap>
    )
};

export default Calculator;