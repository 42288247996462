import {OpenWaterDiverContentData, OpenWaterLessonData, OpenWaterPackageData} from "./data";

import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import DiverInfo from "../../common/diver-info";
import AdvencedPackage from "../../common/advanced-package";
import BtnPayment from "../../common/btn-payment";

const OpenwaterThrifty = () => {
    return (
        <div>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={OpenWaterLessonData}/>
            <DiverInfo info={OpenWaterDiverContentData}/>
            <BtnPayment />
            {/*<AdvencedPackage info={OpenWaterPackageData}/>*/}
        </div>
    )
}

export default OpenwaterThrifty;