import {ItemDataType} from "../../../types/main";
import styled from "styled-components";
import media from "../../../lib/media";

const Wrap = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 29px 0;
    background-color: #C6151B;
    border-radius: 32px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    img{
        width: 48px;
    }
    
    dl{
        display: flex;
        flex-direction: column;
        gap: 8px;
        dt{
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            color: #ffffff;
        }
        dd{
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
            white-space: pre-wrap;
            word-break: keep-all;
        }
    }
    
    ${ media.tablet`
        dl{
            dt{}
            dd{}
        }
    `};
    
    ${ media.mobile`
        padding: 20px 30px;
        justify-content: flex-start;
        dl{
            dt{}
            dd{
                white-space: normal;
            }
        }
    ` };
`


const TeamAboutItem = ({ icon, title, description }: ItemDataType) => {
    return(
        <Wrap>
            <img src={icon} alt=""/>
            <dl>
                <dt>{ title }</dt>
                <dd>{ description }</dd>
            </dl>
        </Wrap>
    )
}


export default TeamAboutItem;