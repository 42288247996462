import {ItemData} from "./data";
import * as S from './styles';
import PackageItem from "./package-item";
import TitleBx from "../../common/title-bx";

const DivePackage = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'DIVE PACKAGE'}
                    description={'팀맥스 다이브 리조트만의 특별한 혜택을 선택해 보세요'}
                />
                <S.ItemBx>
                    {
                        ItemData.map((item, idx) => (
                            <PackageItem
                                key={`package-item-${idx}`}
                                item={item}
                            />
                        ))
                    }
                </S.ItemBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default DivePackage;