import {NoticeItemsType} from "../../../types/main";

export const NoticeData: NoticeItemsType[] = [
    {
        link: '',
        title: '카카오톡 예약 문의',
        date: '02/17',
    },
    {
        link: '',
        title: '카카오톡 예약 문의',
        date: '02/17',
    },
    {
        link: '',
        title: '카카오톡 예약 문의',
        date: '02/17',
    },
]
