import {useCallback, useEffect, useMemo, useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import * as S from "./styles";
import {SampleMessage} from "./styles";
import {NoticeSendType} from "../../../../types/notice";

const formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'align',
    'color',
    'background',
    'size',
    'h1',
];

interface Props {
    onChangeText: (value: string, key: keyof NoticeSendType) => void;
}

const InputText = `
    <p>이름 : </p>
    <p>원하는 날짜 : </p>
    <p>인원 : </p>
    <p>문의 사항 : </p>
`

const QuillEditor = ({onChangeText}: Props) => {
    const [isEditor, setEditor] = useState<boolean>(false);
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{size: ['small', false, 'large', 'huge']}],
                    [{align: []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{list: 'ordered'}, {list: 'bullet'}],
                ],
            },
        };
    }, []);

    const onChange = (value: string) => {
        onChangeText(value, "content");
    }

    const onClickEditor = useCallback(() => {
        setEditor(true);
    }, []);

    return (
        <S.Wrap onClick={onClickEditor}>
            <S.Inner>
                <S.SampleMessage>
                    <span>
                        [ 입력 양식 ]<br/>
                        이름 : (홍길동)<br/>
                        원하는 날짜 : (2024.1.1)~(2024.12.31)<br/>
                        인원 : 남(10) 여(10)<br/>
                        문의 사항 :
                    </span>
                </S.SampleMessage>
                <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    onChange={onChange}
                    placeholder={'test'}
                    defaultValue={isEditor ? '' : InputText}
                />
            </S.Inner>
        </S.Wrap>
    )
}

export default QuillEditor;