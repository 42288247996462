import * as S from "./styles";
import {useState} from "react";

const QrCode = () => {
    const [isShow, setShow] = useState<boolean>(false);

    return (
        <S.Wrap>
            <S.Inner>
                <S.Container onClick={() => setShow((isShow) => !isShow)}>
                    <S.ImgBx $isShow={isShow}>
                        <img src="/image/img_qr_kakao.jpg" alt=""/>
                    </S.ImgBx>
                    <S.Message $isShow={isShow}>문의 하기</S.Message>
                </S.Container>
            </S.Inner>
        </S.Wrap>
    )
}

export default QrCode;