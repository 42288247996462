import styled from "styled-components";
import {motion} from "framer-motion";
import media from "../../../lib/media";

export const ListBx = styled.div`
    justify-content: flex-start;
    align-items: stretch;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
    h3{
        button{
            font-size: 20px;
            font-weight: 700;
        }
    }
    
    ${ media.mobile`
        grid-template-columns: 1fr;
        gap: 20px;
    ` };
`

export const Bx = styled.div`
    border-left: 1px solid #aaaaaa;
    padding-left: 10px;
    
    &:first-child{
        border-left: none;
        padding-left: 0;
    }
    
    ${ media.mobile`
        border-left: none;
        &:nth-child(3){
            margin-left: 10px;
        }
    ` } 
`

export const CheckBx = styled(motion.div)`
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    
    &:hover{
        label{
            > div {
                border-bottom: 1px solid black;
                font-weight: 700;
            }
        }
    }

    input {
        position: relative;
        margin-right: 5px;
        top: -2px;

        &:checked + label {
            > div {
                border-bottom: 1px solid black;
                font-weight: 700;
            }

            > img {
                right: 10px;
            }
        }
    }

    label {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: 500;
            font-size: 16px;
            padding-bottom: 2px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba( 0, 0, 0, 0 );
            gap: 5px;
            word-break: keep-all;

            span {
                display: inline-block;
                padding: 2px 10px;
                font-size: 14px;
                font-weight: 700;
                color: #ffffff;
                background-color: red;
                border-radius: 4px;
            }
        }

        > img {
            position: relative;
            right: 15px;
            transform: rotate(-90deg);
            transition: right 0.2s ease-out;
        }
    }

    ${ media.mobile`
        align-items: flex-start;
    ` };
`
