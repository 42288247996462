import {useEffect, useState} from "react";

function GoogleMap({ container }: { container: HTMLDivElement }){

    const [googleMap, setGoogleMap] = useState<google.maps.Map>();

    useEffect(() => {
        const myLatLng = {
            lat: 10.259170692422858,
            lng: 123.98367459448535,
        }

        const instance = new window.google.maps.Map(container, {
            center: myLatLng,
            zoom: 19,
        });

        new google.maps.Marker({
            position: myLatLng,
            map: instance,
        });

        setGoogleMap(instance);
    } ,[])

    return <></>
}

export default GoogleMap;