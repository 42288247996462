import {createContext, ReactNode, useCallback, useContext, useState} from "react";
import {InfoListTableType} from "../../../types/common";
import PopupTableComponent from "../index";
import {AnimatePresence} from "framer-motion";

export const ToastContext = createContext({
    showToast({info}: { info: InfoListTableType[] }) {},
});

interface Props {
    children: ReactNode
}

const ToastProvider = ({children}: Props) => {
    const [isView, setView] = useState<boolean>(false);
    const [info, setInfo] = useState<InfoListTableType[]>([]);

    const showToast = useCallback(({info}: { info: InfoListTableType[] }) => {
        setInfo(info);
        setView(true);
    }, []);

    const closeToast = useCallback(() => {
        setView(false);
    }, []);

    return (
        <div>
            <ToastContext.Provider value={{showToast}}>
                <AnimatePresence>
                    {
                        isView &&
                        <PopupTableComponent info={info} onClose={closeToast}/>
                    }
                </AnimatePresence>

                {children}
            </ToastContext.Provider>
        </div>
    )
}

export const useToastContext = () => useContext(ToastContext);

export default ToastProvider;