import * as S from "./styles";
import {getNoticeList} from "../../../api/notice";
import React, {useCallback, useEffect, useState} from "react";
import {NoticeSendType} from "../../../types/notice";
import moment from "moment";
import BtnDefault from "../../common/btn-default";
import {useNavigate} from "react-router-dom";

export const NoticeList = () => {
    const navigate = useNavigate();
    const [list, setList] = useState<NoticeSendType[]>();
    const getList = async () => {
        console.log('호출을 두번한다는건가?');
        const data = await getNoticeList();
        setList(data);
    }

    useEffect(() => {
        getList();
    }, []);

    const onClick = useCallback((id: number | undefined) => {
        navigate(`/notice/content?id=${id}`)
    }, []);

    if (!list || list.length === 0) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.NoticeHeader>
                    <dl>
                        <dt>문의 리스트</dt>
                    </dl>
                </S.NoticeHeader>

                <S.ContentListBx>
                    {
                        list.map((item, idx) => (
                            <button
                                key={`notice-list-${idx}`}
                                onClick={() => onClick(item.id)}
                            >
                                <dl>
                                    <dt>{item.id}</dt>
                                    <dd>
                                        <span>
                                            {item.title}
                                        </span>
                                        <span>{moment(item.createdAt).add(9, 'h').format('YYYY/MM/DD')}</span>
                                        {
                                            moment(item.createdAt).add(9, 'h').add('2', 'd').diff(moment()) > 0 &&
                                            <i>NEW</i>
                                        }
                                    </dd>
                                </dl>
                            </button>
                        ))
                    }
                </S.ContentListBx>

                <S.BtnBx>
                    <BtnDefault link={"/notice/write"} title={"문의 작성하기"}></BtnDefault>
                </S.BtnBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default NoticeList;