import styled from "styled-components";
import media from "../../lib/media";


export const Wrap = styled.div`
    padding: 100px 0;
    ${ media.mobile`
        padding-top: 60px;
    ` };
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    
    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;
    
    ${ media.tablet`
        padding: 0 20px;
    ` };
`

