import Razor from "../../components/razor";


const RazorPage = () => {
    return(
        <div>
            <Razor></Razor>
        </div>
    )
}


export default RazorPage;