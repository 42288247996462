import {useCallback, useEffect, useRef, useState} from "react";

export const useInfiniteScroll = (setListNum: any, limit: number) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const areaRef = useRef<HTMLDivElement>(null);

    const loading = useCallback((isLoading: boolean) => {
        setLoading(isLoading);
    }, [isLoading]);

    const stop = useCallback(() => {
        setLoading(true);
    }, [isLoading]);

    useEffect(() => {
        const options = {threshold: 0.1};
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                if (isLoading) return;

                setListNum((n: number) => n + limit);
            }
        }, options);

        if (areaRef.current) observer.observe(areaRef.current);

        return () => {
            if (areaRef.current) {
                observer.unobserve(areaRef.current);
            }
        }
    }, [isLoading]);

    return {
        areaRef,
        loading,
        stop,
    }
}