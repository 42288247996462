import {DiverLessonAddContentType, DiverLessonPackageType, DiverLessonType} from "../../../types/recreation";

export const OpenWaterLessonData: DiverLessonType = {
    title: '이 과정에서는 ...',
    description: '스쿠버 자격증을 받을 수 있는 초급과정(지식, 기술, 안전)에 대하여 배우고 이 과정을 마치면\n전세계 어느 바다에서든 독립적으로 스쿠버 다이빙을 즐기실 수 있습니다.',
    infoList: [
        {
            type: 'TABLE',
            title: '과정 참가자격 및 준비물',
            infoType: [
                {
                    title: '참가자격',
                    description: '15세 (주니어 10 ~ 14세)',
                },
                {
                    title: '소요시간',
                    description: '2일',
                },
                {
                    title: '준비물',
                    description: '수영복, 노트, 개인세면도구, 증명사진 1부',
                },
                {
                    title: '비용',
                    description: '$300',
                }],
        },
        {
            type: 'LIST',
            title: '교육 절차',
            infoList: [
                {
                    description: 'C.R 이론강의',
                },
                {
                    description: 'C.W 제한수역 강습',
                },
                {
                    description: 'O.W 개방수역 강습',
                },
                {
                    description: '스킨다이빙 1회 포함 총 5회 다이빙',
                }],
        },]
}

export const OpenWaterDiverContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '공항픽업',
        },
        {
            title: '숙박2박',
            description: '(2인 1실)',
        },
        {
            title: '조식/중식',
        },
        {
            title: '교육',
        },
        {
            title: '라이센스',
        },
        {
            title: '장비렌탈',
        },
        {
            title: '이론강의',
        },
        {
            title: '수영장교육',
        },
        {
            title: '개방수역',
            description: '(4회 다이빙)',
        },
    ],
    referenceList: [
        {
            title: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.',
        },
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
                link: '/',
            }
        }
    ]
}

export const OpenWaterPackageData: DiverLessonPackageType[] = [
    {
        title: 'NAUI 오픈워터 + 어드밴스드 패키지',
        description: '오픈워터 + 어드밴스드 동시에 등록하시면 총 5일과정을 $800에 할인하여 진행해드립니다!',
        price: '$800',
        list: [
            {
                title: '포함사항',
                description: '숙박 5일 (2인 1실 사용조건), 공항픽업, 조식/중식 , 교재, 장비렌탈',
                subDescription: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.'
            },
            {
                title: '미포함사항',
                description: '아일랜드 펀 다이빙시 해양공원 입장료 & 씨푸드 바베큐 별도',
                linkInfo: {
                    title: '입장료 안내',
                    link: '/',
                }
            }
        ]
    }
]