import styled from "styled-components";
import Visual from "./visual";
import TeamAbout from "./team-about";
import DivePackage from "./dive-package";
import Notice from "./notice";
import TeamMaxStore from "./team-max-store";
import QrCode from "./qr-code";

const Wrap = styled.div`
    
`

const MainContent = () => {
    return(
        <Wrap>
            <QrCode />
            <Visual
                title='TEAM MAX DIVE RESORT'
                imgSrc={["/image/img_about_visual_00.jpg"]}
            />
            <TeamAbout />
            <DivePackage />
            <Notice />
            <TeamMaxStore />
        </Wrap>
    )
}

export default MainContent;