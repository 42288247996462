import styled from "styled-components";
import media from "../../../../lib/media";

export const Wrap = styled.div`
    padding-bottom: 50px;
    
    ${ media.mobile`
        padding-bottom: 10px;
    ` };
`

export const Inner = styled.div`
    text-align: center;
    
    dl{
        dt{
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 15px;
        }
        dd{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            span{
                font-size: 36px;
                line-height: 40px;
                font-weight: 700;
                border-bottom: 4px solid #000000;
                padding: 0 15px;
                word-break: keep-all;
            }
        }
    }
    
    ${ media.mobile`
        dl{
            dt{
                font-size: 20px;
                margin-bottom: 10px;
            }
            dd{
                span{
                    font-size: 24px;
                    line-height: 30px;
                    border-bottom: 3px solid #000000;
                    padding: 0;
                }
            }
        }
    ` };
`
