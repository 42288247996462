import CompleteContentBx from "../../../components/complete-content-bx";
import styled from "styled-components";

const Wrap = styled.div`
    min-height: 100dvh;
    height: auto;
`
const CompletePage = () => {
    return (
        <Wrap>
            <CompleteContentBx />
        </Wrap>
    )
}

export default CompletePage;