import {Link} from "react-router-dom";
import * as S from "./styles";
import {useCallback, useEffect, useState} from "react";
import useAction from "./useAction";
import {useLocation} from "react-router-dom";
import {HeaderData} from "./data";

const Header = () => {
    const location = useLocation();
    const [depthList, setDepthList] = useState<string[]>(
        location.pathname.split("/")
    );

    const [depth1, setDepth1] = useState<string>(`/${depthList[1]}`);
    const [depth2, setDepth2] = useState<string>(`${depth1}/${depthList[2]}`);

    useEffect(() => {
        const url = location.pathname.split("/");

        setIn(false);
        setShowSub([...isShowSub].map(() => false));

        setDepth1(`/${url[1]}`);
        setDepth2(`/${url[1]}/${url[2]}`);

        window.scrollTo( 0, 0 );
    }, [location]);

    const [isIn, setIn] = useState<boolean>(false);
    const [isShowSub, setShowSub] = useState<boolean[]>(
        Array.from({length: HeaderData.length})
    );

    const action = useAction();
    const onMouseEnter = useCallback((idx: number) => {
        const arr = [...isShowSub];
        arr.forEach((_, index) => {
            arr[index] = index === idx;
        });

        setShowSub(arr);
        action.show(idx);

    }, [isShowSub]);

    const onMouseLeave = useCallback(() => {
        setShowSub([...isShowSub].map(() => false));
    }, []);

    const btnHamburgerClick = useCallback(() => {
        setIn(!isIn);
    }, [isIn]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.AdminBx>
                    <ul>
                        <li>
                            <Link to={"/"}>
                                <img src="/icon/ic_key.svg" alt=""/>
                                <span>관리자 로그인</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/"}>
                                <span>추가 메뉴</span>
                            </Link>
                        </li>
                    </ul>
                </S.AdminBx>

                <S.GnbBx ref={action.gnbRef}>
                    <S.LogoBx>
                        <Link to={"/"}>
                            <img src="/image/logo.svg" alt=""/>
                            <h1>TEAM MAX DIVE RESORT</h1>
                        </Link>
                        <button onClick={btnHamburgerClick}>
                            <img
                                src={ isIn ? '/icon/ic_btn_close.svg' : '/icon/ic_btn_hanburger.svg' }
                                alt=""
                            />
                        </button>
                    </S.LogoBx>

                    <S.MenuBx onMouseLeave={onMouseLeave}>
                        <S.Depth1BxContainer $isIn={isIn}>
                            <S.Depth1Bx>
                                {
                                    HeaderData.map((item, idx) => (
                                        <S.Depth1Menu
                                            onMouseEnter={() => onMouseEnter(idx)}
                                            key={`header-menu-${idx}`}
                                            $isOn={depth1 === item.link}
                                        >
                                            {
                                                item.sub ? (
                                                    <button>
                                                        {item.title}
                                                        <span>
                                                            <img src="/icon/ic_arrow_down.svg" alt=""/>
                                                            <img src="/icon/ic_arrow_down_red.svg" alt=""/>
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <Link to={item.link}>{item.title}</Link>
                                                )
                                            }

                                            <S.SubMenuBx className="sub-menu" $isShow={isShowSub[idx]}>
                                                {
                                                    item.sub &&
                                                    <ul>
                                                        {
                                                            item.sub.map((sub, subIdx) => (
                                                                <S.SubMenuItem
                                                                    key={`header-menu-sub-${idx}-${subIdx}`}
                                                                    $isOn={depth2 === sub.link}
                                                                >
                                                                    <Link to={sub.link}>{sub.title}</Link>
                                                                </S.SubMenuItem>
                                                            ))
                                                        }
                                                    </ul>
                                                }
                                            </S.SubMenuBx>
                                        </S.Depth1Menu>
                                    ))
                                }
                            </S.Depth1Bx>

                            <S.KakaoBx>
                                <a href="https://pf.kakao.com/_qNjrxl" target='_blank'>
                                    <img src="/icon/ic_kakaotalk.svg" alt=""/>
                                </a>
                            </S.KakaoBx>
                        </S.Depth1BxContainer>
                    </S.MenuBx>
                </S.GnbBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Header;