import * as S from "./styles";
import {useEffect, useState} from "react";
import {useCalculatorContext} from "../../index";

interface Props {
    setMan: (value: number) => void;
    setWoman: (value: number) => void;
}

const Personnel = ({setMan, setWoman}: Props) => {
    const [man, setManValue] = useState<number>(0);
    const [woman, setWomanValue] = useState<number>(0);
    const {setSchedule} = useCalculatorContext();

    useEffect(() => {
        if( man || woman ){

        }
        setMan(man);
        setWoman(woman);

        setSchedule({
            man: {
                member: man,
            },
            woman: {
                member: woman
            }
        }, 'gender');

    }, [man, woman]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.SelectContainer>
                    <S.SelectBx>
                        <span>남 : </span>
                        <select name="" id="" onChange={(e) => setManValue(+e.target.value)}>
                            {
                                Array.from({length: 20}).map((_, idx) => (
                                    <option key={`personnel-${idx}`} value={idx}>{idx}명</option>
                                ))
                            }
                        </select>
                    </S.SelectBx>
                    <S.SelectBx>
                        <span>여 : </span>
                        <select name="" id="" onChange={(e) => setWomanValue(+e.target.value)}>
                            {
                                Array.from({length: 20}).map((_, idx) => (
                                    <option key={`personnel-${idx}`} value={idx}>{idx}명</option>
                                ))
                            }
                        </select>
                    </S.SelectBx>
                </S.SelectContainer>
            </S.Inner>
        </S.Wrap>
    )
}

export default Personnel;