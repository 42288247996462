import * as S from "./styles";
import TitleBx from "../../common/title-bx";
import SubTitle from "../../common/sub-title";

const Steff = () => {
    return (
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'스쿠버다이빙의 즐거움을 선사 하겠습니다.'}
                    description={'오픈워터부터 강사까지, 전 과정을 안전하게 즐겁게 가르쳐 드리겠습니다.'}
                />

                <S.SteffCEO>
                    <S.ImgList>
                        <img src="/image/img_max_00.jpg" alt=""/>
                        <img src="/image/img_max_01.jpg" alt=""/>
                        <img src="/image/img_max_02.jpg" alt=""/>
                    </S.ImgList>
                    <S.ImgBx>
                        <img src="/image/img_max_03.jpg" alt=""/>
                        <img src="/image/img_max_04.jpg" alt=""/>
                    </S.ImgBx>
                </S.SteffCEO>

                <S.Career>
                    <SubTitle txt={'대표 MAX LEE (이우권)'}/>

                    <S.CareerListBx>
                        <S.CareerList>
                            <ul>
                                <li>NAUI Technical Instructor Examiner</li>
                                <li>NAUI Course Director</li>
                                <li>NAUI Instructor</li>
                                <li>NAUI/DAN First Aid CPR Instructor Trainer</li>
                            </ul>
                            <ul>
                                <li>SDI Instructor Trainer</li>
                                <li>TDI Instructor Trainer</li>
                                <li>FRTI Instructor Trainer</li>
                            </ul>
                        </S.CareerList>
                        <S.CareerList>
                            <ul>
                                <li>Official Razor Instructor</li>
                            </ul>
                            <ul>
                                <li>ANDI Hyperbaric Chamber Technician - Level 3</li>
                                <li>ANDI Hyperbaric Chamber Operator - Level 3</li>
                                <li>ANDI Hyperbaric Chamber Tender - Level 3</li>
                            </ul>
                        </S.CareerList>
                    </S.CareerListBx>
                </S.Career>

                <S.SteffBx>
                    <SubTitle txt={'강사소개'}/>

                    <S.SteffListBx>
                        <S.SteffList>
                            <img src="/image/img_staff_00.jpg" alt=""/>
                            <dl>
                                <dt>한순영</dt>
                                <dd>NAUI Instructor #61213</dd>
                            </dl>
                        </S.SteffList>
                        <S.SteffList>
                            <img src="/image/img_staff_01.jpg" alt=""/>
                            <dl>
                                <dt>박민제</dt>
                                <dd>NAUI Instructor #61036</dd>
                                <dd>PADI IDC STAFF Instructor #400128</dd>
                            </dl>
                        </S.SteffList>
                        <S.SteffList>
                            <img src="/image/img_staff_02.jpg" alt=""/>
                            <dl>
                                <dt>남정익</dt>
                                <dd>SDI INSTRUCTOR #25610</dd>
                            </dl>
                        </S.SteffList>
                        <S.SteffList>
                            <img src="/image/img_staff_03.jpg" alt=""/>
                            <dl>
                                <dt>김진우</dt>
                                <dd>NAUI INSTRUCTOR #55824</dd>
                            </dl>
                        </S.SteffList>
                    </S.SteffListBx>
                </S.SteffBx>

                <S.TeamBx>
                    <SubTitle txt={'편안한 다이빙을 하실 수 있게 최선의 서비스를 제공해 드리겠습니다.'}/>
                    <img src="/image/img_team.jpg" alt=""/>
                </S.TeamBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Steff;