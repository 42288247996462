import * as S from "./styles";
import TableComponent from "../table-component";
import {
    TechnicalTDIData,
    TechnicalPackageData,
    TechnicalNAUIData, TechnicalImagesData,
} from "./data";
import TitleBx from "../common/title-bx";
import AdvencedPackage from "../common/advanced-package";
import ImgSwiper from "../img-swiper";

const Technical = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'TECHNICAL DIVING'}
                    description={'즐거운 바다로, 안전하고 편안하게 안내해 드리겠습니다.'}
                />

                <S.SwiperBx>
                    <ImgSwiper info={TechnicalImagesData} />
                </S.SwiperBx>
                <TableComponent info={TechnicalTDIData}></TableComponent>
                <TableComponent info={TechnicalNAUIData}></TableComponent>
                <AdvencedPackage info={TechnicalPackageData}/>
            </S.Inner>
        </S.Wrap>
    )
}

export default Technical;