import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import {OpenWaterDiverContentData, OpenWaterLessonData} from "./data";
import DiverInfo from "../../common/diver-info";
import BtnPayment from "../../common/btn-payment";


const OpenwaterFun = () => {
    return (
        <div>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={OpenWaterLessonData}/>
            <DiverInfo info={OpenWaterDiverContentData}/>
            {/*<AdvencedPackage info={OpenWaterPackageData}/>*/}
            <BtnPayment />
        </div>
    )
}

export default OpenwaterFun;