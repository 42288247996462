import * as S from "./styles";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const ImgSwiper = ({ info }: { info: string[] }) => {
    return(
        <S.Wrap>
            <S.Inner>
                <S.SwiperBx>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        loop={true}
                        navigation={true}
                        pagination={true}
                        modules={[Navigation, Pagination, Autoplay]}
                        autoplay={{ delay: 3000 }}
                    >
                        {
                            info.map((src, idx) => (
                                <SwiperSlide key={`razor-img-${idx}`}>
                                    <S.ImgBx>
                                        <img src={src} alt="Razor 이미지"/>
                                    </S.ImgBx>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </S.SwiperBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default ImgSwiper;