import styled, {keyframes} from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    padding: 100px 0;
    ${media.mobile`
        padding-top: 30px;
    `};
`

export const Inner = styled.div`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding: 0 20px;
    `};
`

export const NewKeyFrame = keyframes`
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
`

export const NoticeHeader = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }
`

export const ContentListBx = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #cecece;

    button, a {
        &:nth-child(2n) {
            background-color: rgba(0, 0, 0, 0.025);
        }

        dl {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #cecece;
            padding: 20px 10px;
            box-sizing: border-box;
            position: relative;

            dt {
                width: 40px;
                flex-shrink: 0;
                font-size: 14px;
                font-weight: 600;
                color: #000000;
                text-align: center;
                margin-right: 10px;
            }

            dd {
                width: calc(100% - 50px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 30px;

                span {
                    display: block;
                    font-size: 16px;
                    font-weight: 600;
                    color: #000000;

                    &:nth-child(1) {
                        width: 100%;
                        text-align: left;
                        flex-grow: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &:nth-child(2) {
                        font-weight: 500;
                        width: 100px;
                        flex-shrink: 0;
                    }
                }

                i {
                    position: absolute;
                    left: 60px;
                    top: 6px;
                    font-size: 10px;
                    font-weight: 700;
                    color: #d10000;
                    margin-right: 5px;

                    animation: ${NewKeyFrame} 0.75s linear infinite alternate;
                }
            }
        }

        ${media.tablet`
            dl{
                padding: 15px 10px;
                dt{
                    font-size: 11px;
                    font-weight: 600;
                    margin-right: 0;
                    width: 30px;
                }
                dd{
                    span{
                        font-size: 12px;
                        &:nth-child(2){
                            width: 55px;
                        }
                    }
                    
                    i{
                        left: 40px;
                        top: 2px;
                    }
                }
            }
        `}
    }
`

export const BtnBx = styled.div`
    text-align: right;
`