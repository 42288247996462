import Cpr from "../../../components/recreation/cpr";

const CprPage = () => {
    return(
        <div>
            <Cpr></Cpr>
        </div>
    )
}

export default CprPage;