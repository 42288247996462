import {
    css,
    type CSSObject,
    type Interpolation,
} from "styled-components";

export type Breakpoints = "mobile" | "tablet" | "tablet2" | "desktop";

export const breakpoints: Record<Breakpoints, string> = {
    mobile: '@media (max-width: 768px)',
    tablet: '@media (max-width: 1300px)',
    tablet2: '@media (max-width: 1550px)',
    desktop: '@media (min-width: 1920px)',
};

export const media = Object.entries(breakpoints).reduce((acc, [key, value]) => {
    return {
        ...acc,
        [key]: (
            first: CSSObject | TemplateStringsArray,
            ...interpolations: Interpolation<Object>[]
        ) => css`
            ${value} {
                ${css(first, ...interpolations)}
            }
        `,
    };
}, {}) as Record<Breakpoints, any>;

export default media;