import {NoticeItemsType} from "../../../types/main";
import {Link} from "react-router-dom";
import * as S from "./styles"


interface Props {
    title: string
    items: NoticeItemsType[]
}

const NoticeItems = ({title, items}: Props) => {
    return (
        <S.NoticeItemWrap>
            <h3>{title}</h3>
            <S.NoticeList>
                {
                    items.map((item, idx) => (
                        <Link to={item.link} key={`notice-item-${idx}`}>
                            <S.NoticeInfo>
                                <dt>{item.title}</dt>
                                <dd>{item.date}</dd>
                            </S.NoticeInfo>
                        </Link>
                    ))
                }
            </S.NoticeList>
        </S.NoticeItemWrap>
    )
}

export default NoticeItems;