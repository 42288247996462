import StoreItem from "./store-item";
import {TeamMaxStoreData} from "./data";
import * as S from "./styles";
import TitleBx from "../../common/title-bx";

const TeamMaxStore = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'TEAM MAX STORY'}
                    description={''}
                />
                <S.ListBx>
                    {
                        TeamMaxStoreData.map((item, idx) => (
                            <StoreItem
                                key={`team-max-store-${idx}`}
                                item={item}
                            />
                        ))
                    }
                </S.ListBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default TeamMaxStore;