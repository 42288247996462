import OpenwaterAdvanced from "../../../components/recreation/openwater-advanced";

const OpenWaterAdvencedPage = () => {
    return(
        <div>
            <OpenwaterAdvanced />
        </div>
    )
}

export default OpenWaterAdvencedPage;