import DiverInfoItem from "./diver-info-item";
import BtnDefault from "../btn-default";
import * as S from "./styles";
import {DiverLessonAddContentType} from "../../../types/recreation";
import {useInStaggerAction} from "../../../hooks/useInStaggerAction";
import {motion} from "framer-motion";

interface Props {
    info: DiverLessonAddContentType
    onClick?: () => void;
}

const DiverInfo = ({info, onClick}: Props) => {
    const {
        controls,
        ref,
        variants,
        itemVariants
    } = useInStaggerAction({});

    return (
        <S.Wrap>
            <S.Inner
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={variants}
            >
                <h3>포함사항</h3>
                <S.ItemBx>
                    {
                        info.infoList.map((item, idx) => (
                            <motion.div
                                key={`diver-info-item-${idx}`}
                                variants={itemVariants}
                            >
                                <DiverInfoItem
                                    title={item.title}
                                    description={item.description}
                                />
                            </motion.div>
                        ))
                    }
                </S.ItemBx>

                <ul>
                    {
                        info.referenceList.map((item, idx) => (
                            <motion.li
                                key={`diver-info-item-list-${idx}`}
                                variants={itemVariants}
                            >
                                {item.title}
                                {
                                    item.linkInfo &&
                                    <BtnDefault
                                        title={item.linkInfo.title}
                                        link={item.linkInfo.link}
                                        onClick={onClick}
                                    />
                                }
                            </motion.li>
                        ))
                    }
                </ul>

            </S.Inner>
        </S.Wrap>
    )
}

export default DiverInfo;