import styled from "styled-components";
import Visual from "../../main/visual";
import DiveAbout from "./dive-about";
import TeamMaxVideo from "./team-max-video";
import TitleBx from "../../common/title-bx";
import ImgSwiper from "../../img-swiper";
import {AboutFoodImagesData, AboutResortImagesData} from "./data";

const Wrap = styled.div`

`

const PhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 70px 0 60px;
`

const PhotoBx = styled.div`
`

const AboutContent = () => {
    return (
        <Wrap>
            <Visual
                title='아름다운 열대바다를 품고 있는 팀맥스 다이브 리조트'
                description={`아름다운 필리핀 세부 막탄섬 마리곤돈에 위치하고 있는 팀맥스 다이브 리조트는 세계최초의 스쿠버다이빙\n교육 단체인 NAUI를 비롯하여 현재 전세계적으로 가장 경쟁력 있는 교육 단체인 SDI TDI를 바탕으로\n안전하고 보수적인 교육을 지향합니다.`}
                imgSrc={[
                    "/image/img_about_visual.png",
                    "/image/img_about_visual_00.jpg",
                    "/image/img_about_visual_01.jpg",
                    "/image/img_about_visual_02.jpg",
                    "/image/img_about_visual_03.jpg",
                    "/image/img_about_visual_04.jpg",
                    "/image/img_about_visual_05.jpg",
                    "/image/img_about_visual_06.jpg",
                ]}
            />

            <DiveAbout/>

            <PhotoContainer>
                <PhotoBx>
                    <TitleBx
                        title={'리조트'}
                        description={'전객실이 오션&풀사이드 뷰 이며 25M 수영장을 둘러싸고 있습니다.'}
                    />

                    <ImgSwiper info={AboutResortImagesData} />
                </PhotoBx>
                <PhotoBx>
                    <TitleBx
                        title={'식사'}
                        description={'한식 전문 주방장이 여러분들을 위한 양질의 식사를 준비해 드립니다.'}
                    />

                    <ImgSwiper info={AboutFoodImagesData} />
                </PhotoBx>
            </PhotoContainer>


            {/*<TeamMaxVideo/>*/}
        </Wrap>
    )
}

export default AboutContent;