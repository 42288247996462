import styled from "styled-components";


export const Wrap = styled.div`
`

export const Inner = styled.div`
`

export const SelectContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
`

export const SelectBx = styled.div`
    span{
        font-size: 22px;
        font-weight: 700;
    }
    
    select{
        position: relative;
        top: 1px;
        font-size: 22px;
        font-weight: 700;
    }
`