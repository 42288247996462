import {HeaderType} from "../../../types/header";

export const HeaderData: HeaderType[] = [
    {
        title: '팀맥스',
        link: '/team-max',
        sub: [
            {
                title: '팀맥스 소개',
                link: '/team-max/about',
            },
            {
                title: '강사소개',
                link: '/team-max/steff',
            },
            {
                title: '객실 안내',
                link: '/team-max/resort',
            },
            {
                title: '픽업 안내',
                link: '/team-max/contact',
            },
        ]
    },
    {
        title: '비용안내',
        link: '/expense/price',
    },
    {
        title: '교육 패키지',
        link: '/recreation',
        sub: [
            {
                title: '교육 다이빙 비용 정보',
                link: '/recreation/technical-info',
            },
            {
                title: '오픈워터 알뜰',
                link: '/recreation/open-water-thrifty',
            },
            {
                title: '오픈워터 프리미엄',
                link: '/recreation/open-water',
            },
            {
                title: '오픈워터+펀',
                link: '/recreation/open-water-fun',
            },
            {
                title: '어드밴스드',
                link: '/recreation/advanced',
            },
            {
                title: '오픈워터+어드밴스드',
                link: '/recreation/open-water-advanced',
            },
            {
                title: '나이트록스',
                link: '/recreation/nitrox',
            },
            {
                title: '응급처치',
                link: '/recreation/cpr',
            },
            {
                title: '레스큐',
                link: '/recreation/rescue',
            },
            {
                title: '마스터 스쿠버',
                link: '/recreation/master',
            },
            {
                title: '강사코스',
                link: '/recreation/instructor',
            },
        ]
    },
    {
        title: '펀다이빙 패키지',
        link: '/fundiving',
        // sub: [
        // {
        //     title: '비용안내',
        //     link: '/expense/price',
        // },
        // {
        //     title: '비용 계산기',
        //     link: '/expense/calculator',
        // },
        // {
        //     title: '비용 작성 리스트',
        //     link: '/expense/list'
        // }
        // ]
    },
    {
        title: '테크니컬 교육',
        link: '/technical',
    },
    {
        title: 'RAZOR 교육',
        link: '/razor',
    },
];