import Contact from "../../../components/team-max/contact";

const ContactPage = () => {
    return(
        <div>
            <Contact />
        </div>
    )
}

export default ContactPage;