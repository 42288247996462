import styled from "styled-components";


export const Wrap = styled.div`
    
`
export const Inner = styled.div`
    
`

export const InputBx = styled.div`
    margin-bottom: 10px;
    input{
        padding: 5px;
        box-sizing: border-box;
    }
`

export const MessageBx = styled.div`
    
    textarea{
        width: 100%;
        max-width: 500px;
        height: 200px;
        padding: 5px;
        box-sizing: border-box;
    }
`