import styled from "styled-components";
import media from "../../../../../../lib/media";

export const Wrap = styled.div`
`

export const BtnDate = styled.div`
    
`

export const CalendarContainer = styled.div`
    margin-top: 10px;
`

export const RangeContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
`

export const RangeBx = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;

    span {
        position: absolute;
        display: inline-flex;
        text-wrap: nowrap;
        background-color: #ffffff;
        padding: 0 20px;
        left: 50%;
        top: -8px;
        transform: translateX(-50%);
        font-size: 14px;
    }

    ${media.mobile`
        width: 100%;
        font-size: 15px;
        padding: 20px 10px;
        height: 55px;
    `};
`