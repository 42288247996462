import * as S from "./styles";
import {useCallback, useEffect, useState} from "react";
import {getNoticeReplyList, setNoticeReply} from "../../../../api/notice";
import moment from "moment";

interface Props {
    noticeId: number;
}

const Reply = ({noticeId}: Props) => {
    const [replyList, setReplyList] = useState<any>(null);
    const [replyContent, setReplyContent] = useState<string>('');
    const getReplyList = async () => {
        const data = await getNoticeReplyList(+noticeId);

        setReplyList(data);
        setReplyContent('');
    }

    const addReply = async () => {
        if( replyContent === '' ){
            alert( '댓글을 입력해주세요.' );
            return;
        }
        await setNoticeReply(noticeId, replyContent);
        getReplyList()
    }

    const onClick = useCallback(() => {
        addReply();
    }, [noticeId, replyContent]);

    const onChange = useCallback((value: string) => {
        setReplyContent(value);
    }, []);

    useEffect(() => {
        getReplyList();
    }, []);

    return (
        <S.Wrap>
            <S.Inner>
                <S.ListBx>
                    {
                        replyList?.map((item: any, idx: number) => (
                            <dl key={`reply-list-${idx}`}>
                                <dt>
                                    {item.content}
                                </dt>
                                <dd>
                                    {
                                        moment(item.createdAt).add(9, 'h').format("YYYY. MM. DD. HH:mm:ss")
                                    }
                                </dd>
                            </dl>
                        ))
                    }
                </S.ListBx>

                <S.ReplyBx>
                    <h3>댓글</h3>
                    <textarea
                        placeholder={'댓글을 남겨보세요.'}
                        onChange={(e) => onChange(e.target.value)}
                        value={replyContent}
                    />
                    <S.Btn>
                        <button onClick={onClick}>
                            댓글 등록
                        </button>
                    </S.Btn>
                </S.ReplyBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Reply;