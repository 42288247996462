import {InfoListTableType} from "../../types/common";

export const ResortPriceDatas: InfoListTableType[] = [
    {
        title: '리조트 객실',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '숙박',
                    },
                    {
                        price: '$120',
                        description: '2인1실 기준 조식포함'
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '비다이버 패키지',
                    },
                    {
                        price: '$70',
                        description: '1인요금 조,중식 포함'
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '싱글챠지',
                    },
                    {
                        price: '$35',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '레이트 체크아웃',
                    },
                    {
                        price: '$40',
                        description: '오후 6시 이후'
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '공항 픽업',
                    },
                    {
                        description: '1인',
                        addPrice: '$10'
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '공항 드랍',
                    },
                    {
                        description: '1인',
                        addPrice: '$10'
                    }
                ]
            },
        ],
    },
]

export const DivingPriceDatas: InfoListTableType[] = [
    {
        title: '다이빙 요금',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '주간보트다이빙',
                    },
                    {
                        price: '$40',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '주간비치다이빙',
                    },
                    {
                        price: '$30',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '야간보트다이빙',
                    },
                    {
                        price: '$50',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '야간비치다이빙',
                    },
                    {
                        price: '$40',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '나이트록스',
                    },
                    {
                        price: '$15',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '체험다이빙',
                    },
                    {
                        price: '$80',
                    }
                ]
            },
        ],
    },
]

export const FoodPriceDatas: InfoListTableType[] = [
    {
        title: '식사',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '조식',
                    },
                    {
                        price: '$10',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '중식',
                    },
                    {
                        price: '$15',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '석식',
                    },
                    {
                        price: '$20',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '삼겹살 무한 리필',
                    },
                    {
                        price: '$25',
                    }
                ]
            },
        ],

        messageList: [
            {
                title: '레쵼 바비큐 싯가\n만4세 이하 영유아 무료',
                message: '',
            },
        ]
    },
]

export const RentalPriceDatas: InfoListTableType[] = [
    {
        title: '장비 렌탈',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: 'Full set',
                    },
                    {
                        price: '$25',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'BCD',
                    },
                    {
                        price: '$15',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'Reg',
                    },
                    {
                        price: '$13',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'Computer',
                    },
                    {
                        price: '$10',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: 'Flash light',
                    },
                    {
                        price: '$10',
                    }
                ]
            },
        ],
    },
]

export const ParkPriceDatas: InfoListTableType[] = [
    {
        title: '해상공원 입장료',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '올랑고',
                    },
                    {
                        price: '$2',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '힐룽뚱안',
                    },
                    {
                        price: '$6',
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '날루수안',
                    },
                    {
                        price: '$6',
                    }
                ]
            },
        ],
    },
]