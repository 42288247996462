import styled from "styled-components";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import DiverInfo from "../../common/diver-info";
import {OpenWaterLessonData, OpenWaterDiverContentData, OpenWaterPackageData} from "./data";
import AdvencedPackage from "../../common/advanced-package";
import BtnPayment from "../../common/btn-payment";

const Wrap = styled.div`
    position: relative;
`

const OpenWater = () => {
    return (
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={OpenWaterLessonData}/>
            <DiverInfo info={OpenWaterDiverContentData}/>
            {/*<AdvencedPackage info={OpenWaterPackageData}/>*/}
            <BtnPayment />
        </Wrap>
    )
}

export default OpenWater;