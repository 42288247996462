import * as S from "./styles";
import TableComponent from "../table-component";
import {PriceContentData} from "./data";
import TitleBx from "../common/title-bx";

const FunDiving = () => {
    return (
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'펀다이빙 비용'}
                    description={'합리적인 가격으로 열대바다의 아름다움과 스쿠버다이빙을 즐기세요.'}
                />

                <TableComponent info={PriceContentData}></TableComponent>
            </S.Inner>
        </S.Wrap>
    )
}

export default FunDiving;