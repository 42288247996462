
import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    padding-top: 100px;
    
    ${ media.mobile`
        padding-top: 60px;
    ` }
`

export const TotalBx = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto 50px;
    position: sticky;
    top: 96px;
    z-index: 100;
    background-color: #ffffff;
    box-sizing: border-box;
    
    > span{
        display: block;
        font-size: 30px;
        font-weight: 700;
        border-bottom: 1px solid rgba(0, 0, 0, 1);
        padding-bottom: 10px;
    }

    ${media.tablet2`
        top: 168px;
    `}

    ${media.tablet`
        top: 152px;
        padding: 0px 20px;
        margin-bottom: 0px;
    `}

    ${media.mobile`
        top: 72px;
        span{
            font-size: 24px;
        }
    `}
`
