import * as S from "./styles";
import TitleBx from "../common/title-bx";
import TableComponent from "../table-component";
import {InstructorNAUIData, InstructorSDIData} from "./data";

const Instructor = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'강사 코스'}
                    description={'당신도 프로가 될수 있습니다.'}
                />

                <TableComponent info={InstructorNAUIData}></TableComponent>
                <TableComponent info={InstructorSDIData}></TableComponent>
            </S.Inner>
        </S.Wrap>
    )
}

export default Instructor;