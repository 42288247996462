import BtnDefault from "../btn-default";
import * as S from "./styles";
import {DiverLessonPackageType} from "../../../types/recreation";
import {useInStaggerAction} from "../../../hooks/useInStaggerAction";

const AdvencedPackage = ({info}: { info: DiverLessonPackageType[] }) => {
    const {
        ref,
        controls,
        variants,
        itemVariants
    } = useInStaggerAction({
        staggerTime: 0.2,
        duration: 0.25,
        amount: 0.25
    });

    return (
        <S.Wrap ref={ref}>
            <S.Inner
                initial="hidden"
                animate={controls}
                variants={variants}
            >
                {
                    info.map((item, idx) => (
                        <S.ContentBx
                            key={`package-item-${idx}`}
                            variants={itemVariants}
                        >
                            <S.PriceBx>
                                {item.price}
                            </S.PriceBx>
                            <S.TitleBx>
                                <dl>
                                    <dt>{item.title}</dt>
                                    {item.description && <dd>{item.description}</dd>}
                                </dl>
                            </S.TitleBx>

                            <S.ListBx>
                                {
                                    item.list.map((v, sIdx) => (
                                        <S.ListItem key={`package-item-list-${idx}-${sIdx}`}>
                                            <h4>{v.title}</h4>
                                            <dl>
                                                <dt>{v.description}</dt>
                                                <dd>
                                                    {v.subDescription}
                                                    {
                                                        v.linkInfo &&
                                                        <BtnDefault
                                                            title={v.linkInfo.title}
                                                            link={v.linkInfo.link}
                                                        />
                                                    }
                                                </dd>
                                            </dl>
                                        </S.ListItem>
                                    ))
                                }
                            </S.ListBx>
                        </S.ContentBx>
                    ))
                }
            </S.Inner>
        </S.Wrap>
    )
}

export default AdvencedPackage;