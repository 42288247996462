import styled from "styled-components";
import media from "../../../../../lib/media";

export const Wrap = styled.div`
    
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0 100px;
    height: auto;
    
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-sizing: border-box;
    
    ${ media.tablet`
        padding: 100px 20px;
    ` };
`

export const ContentBx = styled.div<{ $isView?: boolean }>`
    border-left: 2px solid #999999;
    box-sizing: border-box;
    padding-left: 20px;
    h3{
        font-weight: 700;
        margin-bottom: 20px;
        font-size: ${ p => p.$isView ? '24px' : '18px' };
        color: ${ p => p.$isView ? '#000000' : '#999999' };
    }
    
    ${ media.mobile`
        padding-left: 0px;
        border-left: none;
    ` };
`

export const Bx = styled.div`
    display: flex;
    flex-direction: column;
    gap: 80px;
`

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`
