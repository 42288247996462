import styled from "styled-components";
import media from "../../../lib/media";


export const Wrap = styled.div`

`

export const Inner = styled.div`

`

export const ProcessBx = styled.div`
    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
`

export const ProcessItem = styled.li<{ $isSelect: boolean }>`
    opacity: ${p => p.$isSelect ? 1 : 0.7};
    transition: opacity 0.1s ease-out;
    &:hover {
        opacity: 1;
    }

    input {
        display: none;
    }

    label {
        max-width: 200px;
        width: 100%;
        aspect-ratio: 1/1;
        
        position: relative;
        display: block;
        border-radius: 10px;
        overflow: hidden;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 700;
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.4);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    
    ${ media.mobile`
        label{
            span{
                font-size: 18px;
            }
        }
    ` };
`