export const AboutFoodImagesData: string[] = [
    '/image/img_food_000.jpg',
    '/image/img_food_001.jpg',
    '/image/img_food_002.jpg',
    '/image/img_food_003.jpg',
    '/image/img_food_004.jpg',
    '/image/img_food_005.jpg',
    '/image/img_food_006.jpg',
    '/image/img_food_007.jpg',
    '/image/img_food_008.jpg',
    '/image/img_food_009.jpg',
]

export const AboutResortImagesData: string[] = [
    '/image/img_resort_000.jpg',
    '/image/img_resort_001.jpg',
    '/image/img_resort_003.jpg',
    '/image/img_resort_011.jpg',
    '/image/img_about_visual_02.jpg',
    '/image/img_about_visual_04.jpg',
    '/image/img_about_visual_05.jpg',
    '/image/img_about_visual_07.jpg',
]