import {InfoListTableType} from "../../../types/common";

export const TechnicalDivingData: InfoListTableType[] = [
    {
        title: '',
        subTitle: ['구분', '금액'],
        list: [
            {
                technicalCode: 'open-water-thrifty',
                contents: [
                    { description: '오픈워터 알뜰 패키지 2일과정', link: '/recreation/open-water-thrifty', },
                    {
                        price: '$300',
                        content: {
                            title: '포함사항',
                            contentList: [
                                { title: '공항픽업', },
                                {
                                    title: '숙박2박',
                                    description: '(2인 1실)',
                                },
                                { title: '조식/중식', },
                                { title: '교육', },
                                { title: '라이센스', },
                                { title: '장비렌탈', },
                                { title: '이론강의', },
                                { title: '수영장교육', },
                                {
                                    title: '개방수역',
                                    description: '(4회 다이빙)',
                                },
                            ]
                        },
                        descriptionList: [
                            '* 준비물: 수영복, 노트, 개인세면도구',
                        ]
                    }
                ]
            },
            {
                technicalCode: 'open-water',
                contents: [
                    { description: '오픈워터 프리미엄 패키지 3일과정', link: '/recreation/open-water', },
                    {
                        price: '$450',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: '공항픽업',
                                },
                                {
                                    title: '숙박3박',
                                    description: '(2인1실)',
                                },
                                { title: '조식/중식', },
                                { title: '교육', },
                                { title: '라이센스', },
                                { title: '장비렌탈', },
                                {
                                    title: '이론강의',
                                },
                                {
                                    title: '수영장교육',
                                },
                                {
                                    title: '개방수역',
                                    description: '(7회 다이빙)',
                                },
                            ]
                        },
                        descriptionList: [
                            '* 준비물: 수영복,노트,개인세면도구'
                        ]
                    }
                ]
            },
            {
                technicalCode: 'open-water-fun',
                contents: [
                    { description: '오픈워터+펀 패키지 3일과정', link: '/recreation/open-water-fun', },
                    {
                        price: '$450',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: '공항픽업',
                                },
                                {
                                    title: '숙박3박',
                                    description: '(2인1실)',
                                },
                                { title: '조식/중식', },
                                {
                                    title: '교육',
                                    description: '(2일)'
                                },
                                { title: '라이센스', },
                                { title: '장비렌탈', },
                                {
                                    title: '이론강의',
                                },
                                {
                                    title: '수영장교육',
                                },
                                {
                                    title: '개방수역',
                                    description: '(4회 다이빙)',
                                },
                                {
                                    title: '펀다이빙',
                                    description: '(3회)',
                                },
                            ]
                        },
                        descriptionList: [
                            '* 준비물: 수영복,노트,개인세면도구'
                        ]
                    }
                ]
            },
            {
                technicalCode: 'advanced',
                contents: [
                    { description: '어드밴스드 패키지 2일과정', link: '/recreation/advanced',  },
                    {
                        price: '$350',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: '공항픽업',
                                    description: '',
                                },
                                {
                                    title: '숙박2박',
                                    description: '(2인 1실)',
                                },
                                {
                                    title: '조식/중식',
                                    description: '',
                                },
                                {
                                    title: '교육',
                                    description: '',
                                },
                                {
                                    title: '라이센스',
                                    description: '',
                                },
                                {
                                    title: '장비렌탈',
                                    description: '',
                                },
                                {
                                    title: '이론강의',
                                    description: '',
                                },
                                {
                                    title: '개방수역',
                                    description: '(6회 다이빙)',
                                },
                            ]
                        },
                    }
                ]
            },
            {
                technicalCode: 'open-water-advanced',
                contents: [
                    { description: '오픈워터+어드밴스드 패키지 5일과정', link: '/recreation/open-water-advanced',  },
                    {
                        price: '$750',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: '공항픽업',
                                    description: '',
                                },
                                {
                                    title: '숙박5박',
                                    description: '(2인 1실)',
                                },
                                {
                                    title: '조식/중식',
                                    description: '',
                                },
                                {
                                    title: '교육',
                                    description: '',
                                },
                                {
                                    title: '라이센스',
                                    description: '',
                                },
                                {
                                    title: '장비렌탈',
                                    description: '',
                                },
                                {
                                    title: '이론강의',
                                    description: '',
                                },
                                {
                                    title: '개방수역',
                                    description: '(10회 다이빙)',
                                },
                            ]
                        },
                        descriptionList: [
                        ]
                    }
                ]
            },
            {
                technicalCode: 'nitrox',
                contents: [
                    { description: '나이트록스 다이버', link: '/recreation/nitrox',  },
                    {
                        price: '$300',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: '점심식사',
                                },
                                {
                                    title: '장비렌탈',
                                    description: '',
                                },
                                {
                                    title: '나이트록스 이론강의\n32% 36%',
                                    description: '(2회 다이빙)',
                                },
                            ]
                        },
                        descriptionList: [
                            '* 나이트록스 이론강의 32% 36%( 2회 다이빙 )'
                        ]
                    }
                ]
            },
            {
                technicalCode: 'cpr',
                contents: [
                    { description: '응급조치', link: '/recreation/cpr',  },
                    {
                        price: '$300',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: 'FRTI/\nbasic Life',
                                    description: '',
                                },
                                {
                                    title: 'Supporter/\nFirst Aid CPR',
                                    description: '',
                                },
                            ]
                        },
                    }
                ]
            },
            {
                technicalCode: 'rescue',
                contents: [
                    { description: '레스큐 다이버 패키지 3일과정', link: '/recreation/rescue',  },
                    {
                        price: '$500',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: '공항픽업',
                                    description: '',
                                },
                                {
                                    title: '숙박3박',
                                    description: '(2인1실)',
                                },
                                {
                                    title: '조식/중식',
                                    description: '',
                                },
                                {
                                    title: '교육',
                                    description: '',
                                },
                                {
                                    title: '라이센스',
                                    description: '',
                                },
                                {
                                    title: '장비렌탈',
                                    description: '',
                                },
                                {
                                    title: '이론강의',
                                    description: '',
                                },
                                {
                                    title: '수영장교육',
                                    description: '',
                                },
                                {
                                    title: '개방수역',
                                    description: '(다이빙)',
                                },
                            ]
                        },
                    }
                ]
            },
            {
                technicalCode: 'master',
                contents: [
                    { description: '마스터스쿠버 다이버 패키지 5일과정', link: '/recreation/master',  },
                    {
                        price: '$1000',
                        content: {
                            title: '포함사항',
                            contentList: [
                                {
                                    title: '공항픽업',
                                },
                                {
                                    title: '숙박5박',
                                    description: '(2인1실)',
                                },
                                {
                                    title: '조식/중식',
                                },
                                {
                                    title: '교육',
                                },
                                {
                                    title: '라이센스',
                                },
                                {
                                    title: '장비렌탈',
                                },
                                {
                                    title: '이론강의',
                                },
                                {
                                    title: '수영장교육',
                                },
                                {
                                    title: '개방수역',
                                    description: '(다이빙)',
                                },
                            ]
                        },
                        descriptionList: [
                        ]
                    }
                ]
            },
        ],
    }
]
