import {useAnimation, useInView} from "framer-motion";
import {useEffect, useRef} from "react";

interface Props {
    staggerTime?: number;
    duration?: number;
    amount?: number;
}

export function useInStaggerAction(
    option: Props
) {
    const {
        staggerTime = 0.1,
        duration = 0.2,
        amount = 0.5
    } = option;

    const variants = {
        hidden: {},
        show: {
            transition: {
                ease: 'easeOut',
                staggerChildren: staggerTime,
            }
        }
    }

    const itemVariants = {
        hidden: {opacity: 0, y: 15},
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: duration
            }
        }
    }

    const controls = useAnimation();
    const ref = useRef(null);
    const isView = useInView(ref, {
        once: true,
        amount: amount,
    });

    useEffect(() => {
        if (isView) controls.start('show');
    }, [isView]);

    return {
        controls,
        ref,
        variants,
        itemVariants
    }
}