import styled from "styled-components";
import TableComponent from "../table-component";
import {RazorPriceData, RazorContentData, RazorImagesData} from "./data";
import DiverInfo from "../common/diver-info";
import TitleBx from "../common/title-bx";
import ImgSwiper from "../img-swiper";
import media from "../../lib/media";

const Wrap = styled.div``;
const Inner = styled.div`
    padding: 100px 0 0;
    ${ media.mobile`
        padding: 60px 0 0;
    ` };
`

const SwiperBx = styled.div`
    margin-bottom: 50px;
`

const Razor = () => {
    return(
        <Wrap>
            <Inner>
                <TitleBx
                    title={'RAZOR SIDE MOUNT COURSE'}
                    description={'즐거운 바다로, 안전하고 편안하게 안내해 드리겠습니다.'}
                />

                <SwiperBx>
                    <ImgSwiper info={RazorImagesData} />
                </SwiperBx>
                <TableComponent info={RazorPriceData}></TableComponent>
                <DiverInfo info={RazorContentData}/>
            </Inner>
        </Wrap>
    )
}

export default Razor;