import * as S from "./styles";

const BtnPayment = () => {
    return null;

    return (
        <S.Wrap>
            <S.Inner>
                <dl>
                    <dt>과정명 :</dt>
                    <dd>(샘플)오픈워터 알뜰 패키지 2일 과정</dd>
                </dl>
                <button>
                    구매하기
                </button>
            </S.Inner>
        </S.Wrap>
    )
}

export default BtnPayment;