import Nitrox from "../../../components/recreation/nitrox";

const NitroxPage = () => {
    return(
        <div>
            <Nitrox />
        </div>
    )
}

export default NitroxPage;