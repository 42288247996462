import * as S from "../styles";
import {getComma} from "../../../../lib/comma";

interface Props {
    technicalData: any;
}

const ProductInfo = ({technicalData}: Props) => {
    return (
        <S.Wrap>
            <S.Inner>
                <S.ContentBx>
                    <h2>상품 정보</h2>

                    <S.ListBx>
                        <dl>
                            <dt>
                                <span>상품 이름</span>
                            </dt>
                            <dd>
                                {technicalData.title}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <span>상세 정보</span>
                            </dt>
                            <dd>
                                <S.InfoBx>
                                    {
                                        technicalData.list.map((item: any, idx: number) => (
                                            <li key={`t-list-${idx}`}>
                                                {item.title}
                                                <span>{item.description}</span>
                                            </li>
                                        ))
                                    }
                                </S.InfoBx>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <span>상품 가격</span>
                            </dt>
                            <dd>
                                <S.PriceBx>
                                    {getComma(technicalData.price)}
                                </S.PriceBx>
                            </dd>
                        </dl>
                    </S.ListBx>

                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default ProductInfo;