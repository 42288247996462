import AboutContent from "../../../components/team-max/about";


const AboutPage = () => {
    return(
        <div>
            <AboutContent />
        </div>
    )
}

export default AboutPage;