import styled from "styled-components";

export const Wrap = styled.div`
    
`

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    
    dl{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        dt{
            font-size: 16px;
            font-weight: 700;
        }
        dd{
            input{
                padding: 5px 10px;
                box-sizing: border-box;
                border-radius: 7px;
                overflow: hidden;
                border: 1px solid #000000;
            }
        }
    }
`
