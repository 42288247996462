import OpenwaterThrifty from "../../../components/recreation/openwater-thrifty";

const OpenWaterThriftyPage = () => {
    return(
        <div>
            <OpenwaterThrifty />
        </div>
    )
}

export default OpenWaterThriftyPage;