import * as S from "./styles";
import {InfoListTableType} from "../../types/common";
import TableComponent from "../table-component";

interface Props {
    info: InfoListTableType[];
    onClose: () => void;
}

const PopupTableComponent = ({info, onClose}: Props) => {
    return (
        <S.Wrap>
            <S.Inner
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{
                    ease: 'easeOut',
                    duration: 0.2,
                }}
            >
                <S.ContentBx
                    initial={{y: 20}}
                    animate={{y: 0}}
                    exit={{y: 20}}
                    transition={{
                        ease: 'easeOut',
                        duration: 0.2
                    }}
                >
                    <S.TitleBx>
                        <h3>TEAM MAX DIVE TOP TEAM</h3>
                        <button onClick={onClose}>
                            <img src="/icon/ic_btn_close_white.svg" alt=""/>
                        </button>
                    </S.TitleBx>

                    <S.Content>
                        <TableComponent info={info}/>
                    </S.Content>

                </S.ContentBx>
                <S.Dimmed onClick={onClose}/>
            </S.Inner>
        </S.Wrap>
    )
}

export default PopupTableComponent;