import Advanced from "../../../components/recreation/advanced";
import styled from "styled-components";

const Wrap = styled.div`
`;

const AdvancedPage = () => {
    return(
        <Wrap>
            <Advanced />
        </Wrap>
    )
}

export default AdvancedPage;