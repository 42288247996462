import {InfoListTableType} from "../../types/common";
import {DiverLessonAddContentType} from "../../types/recreation";

export const RazorPriceData: InfoListTableType[] = [
    {
        title: 'RAZOR SIDE MOUNT COURSE 비용',
        subTitle: ['과정명', '소요일정', '금액'],
        list: [
            {
                contents: [
                    { description: 'BASIC SIDEMOUNT', },
                    { description: '최소 5일', },
                    {
                        description: '1일',
                        addPrice: '$300',
                    },
                ]
            },
            {
                contents: [
                    { description: 'SIDEMOUNT RESCUE', },
                    { description: '최소 1일', },
                    {
                        description: '1일',
                        addPrice: '$300',
                    },
                ]
            },
            {
                contents: [
                    { description: 'ADVANCED SIDEMOUNT', },
                    { description: '최소 4일', },
                    {
                        description: '1일',
                        addPrice: '$300',
                    },
                ]
            },
            {
                contents: [
                    { description: 'STAGE SIDEMOUNT', },
                    { description: '최소 4일', },
                    {
                        description: '1일',
                        addPrice: '$300',
                    },
                ]
            },
            {
                contents: [
                    { description: 'MULTI STAGE SIDEMOUNT', },
                    { description: '최소 4일', },
                    {
                        description: '1일',
                        addPrice: '$300',
                    },
                ]
            },
            {
                contents: [
                    { description: 'DPV SDIEMOUNT', },
                    { description: '최소 3일', },
                    {
                        description: '1일',
                        addPrice: '$300',
                    },
                ]
            },
        ],

        messageList: [
            {
                title: '',
                message: 'GO SIDEMOUNT 교육비 하루 300$',
            }
        ]
    }
]


export const RazorContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '소요일정간의 숙박',
            description: '(2인 1실 사용조건)'
        },
        {
            title: '공항픽업',
        },
        {
            title: '조식/중식',
        },
    ],
    referenceList: [
        {
            title: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.',
        },
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
                link: '/',
            }
        }
    ]
}

export const RazorImagesData: string[] = [
    '/image/img_razor_000.jpg',
    '/image/img_razor_001.jpg',
    '/image/img_razor_002.jpg',
    '/image/img_razor_003.jpg',
    '/image/img_razor_004.jpg',
    '/image/img_razor_005.jpg',
]