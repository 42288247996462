import styled from "styled-components";
import media from "../../../lib/media";


const Wrap = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
`

const Inner = styled.div`
    width: 100%;
    overflow: hidden;
    img{
        width: 1920px;
        height: 262px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        object-position: center;
    }
    
    ${ media.mobile`
        img{
            width: 100%;
            height: auto;
        }   
    ` };
    
`

interface Props {
    imgSrc: string;
}

const DiverVisual = ({imgSrc}: Props) => {
    return (
        <Wrap>
            <Inner>
                <img src={imgSrc} alt=""/>
            </Inner>
        </Wrap>
    )
}

export default DiverVisual;