import styled from "styled-components";
import media from "../../../lib/media";

const TitleBx = styled.div`
    text-align: center;
    padding: 15px 0;
    margin: 15px 0 30px;
    display: flex;
    justify-content: center;
    h3{
        border-bottom: 3px solid #C6151B;
        font-size: 40px;
        font-weight: 700;
        color: #0F0F10;
        padding: 0 15px 10px;
        word-break: keep-all;
    }
    
    ${ media.tablet`
    
    ` }
    ${ media.mobile`
        h3{
            font-size: 24px;     
        }
    ` }
    
`

const SubTitle = ({ txt }: { txt: string }) => {
    return(
        <TitleBx>
            <h3>{txt}</h3>
        </TitleBx>
    )
}

export default SubTitle;