import {useCallback, useEffect, useRef} from "react";
import {Cubic, gsap} from "gsap";


function useAction(){
    const gnbRef = useRef<HTMLDivElement>(null);

    const show = useCallback((idx: number) => {
        if( gnbRef.current ){
            const gnbBx = gnbRef.current as HTMLDivElement;
            if (gnbBx) {
                const subMenus = gnbBx.querySelectorAll('.sub-menu')[idx];

                let sm = subMenus.querySelectorAll("li");
                sm.forEach((item, index) => {
                    gsap.killTweensOf(item);
                    gsap.fromTo(item,
                        {opacity: 0, x: 50},
                        {opacity: 1, x: 0, ease: Cubic.easeOut, duration: 0.35, delay: 0.05 * index}
                    );
                })
            }
        }
    }, []);

    return{
        gnbRef,
        show,
    }
}

export default useAction;