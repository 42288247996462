import Technical from "../../components/technical";


const TechnicalPage = () => {
    return(
        <div>
            <Technical />
        </div>
    )
}

export default TechnicalPage;