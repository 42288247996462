import {createContext, ReactNode, useCallback, useContext, useState} from "react";
import {AnimatePresence} from "framer-motion";
import * as S from "./styles";

export const PopupContext = createContext({
    showMessage(component: ReactNode) {
    },
    closeMessage() {
    },
});

interface Props {
    children: ReactNode
}

const PopupProvider = ({children}: Props) => {
    const [content, setContent] = useState<ReactNode>(null);
    const [isClose, setClose] = useState<boolean>(false);
    const showMessage = useCallback((component: ReactNode) => {
        setContent(component);
    }, []);

    const onCloseClick = useCallback(() => {
        setContent(null);
        setClose(true);
    }, []);

    const closeMessage = useCallback(() => {
        setContent(null);
        setClose(true);
    }, []);

    return (
        <div>
            <PopupContext.Provider value={{showMessage, closeMessage}}>
                <AnimatePresence>
                    {
                        content && <S.Wrap
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{
                                ease: 'easeOut',
                                duration: 0.2,
                            }}
                        >
                            <S.Inner
                                initial={{y: 20}}
                                animate={{y: 0}}
                                exit={{y: 20}}
                                transition={{
                                    ease: 'easeOut',
                                    duration: 0.2,
                                }}
                            >
                                <S.CloseBx>
                                    <button onClick={onCloseClick}>
                                        <img src="/icon/ic_btn_close_white.svg" alt=""/>
                                    </button>
                                </S.CloseBx>
                                <S.Container>
                                    <S.ContentBx>
                                        {content}
                                    </S.ContentBx>
                                </S.Container>
                            </S.Inner>
                            <S.Dimmed onClick={onCloseClick}></S.Dimmed>
                        </S.Wrap>
                    }
                </AnimatePresence>
                {children}
            </PopupContext.Provider>
        </div>
    )
}

export const usePopupContext = () => useContext(PopupContext);

export default PopupProvider;