import styled from "styled-components";
import media from "../../../lib/media";
import {motion} from "framer-motion";

export const CheckBx = styled(motion.div)`
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:hover {
        label {
            > div {
                border-bottom: 1px solid black;
                font-weight: 700;
            }
        }
    }

    input {
        position: relative;
        margin-right: 5px;

        &:checked + label {
            > div {
                border-bottom: 1px solid black;
                font-weight: 700;
            }

            > img {
                right: 10px;
            }
        }
    }

    label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        padding-left: 25px;

        > div {
            font-weight: 500;
            font-size: 16px;
            padding-bottom: 2px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(0, 0, 0, 0);

            span {
                display: inline-block;
                padding: 2px 10px;
                font-size: 14px;
                font-weight: 700;
                color: #ffffff;
                background-color: red;
                border-radius: 4px;
                margin-left: 10px;
            }
        }

        > img {
            position: relative;
            right: 15px;
            transform: rotate(-90deg);
            transition: right 0.2s ease-out;
        }
    }
`

export const MoreBx = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    
    ${ media.tablet`
        grid-template-columns: repeat(2, 1fr);
    ` }

    ${ media.mobile`
        grid-template-columns: repeat(1, 1fr);
    ` }
`

export const MoreListBx = styled(motion.div)`
    border: 1px solid rgba( 0, 0, 0, 0.1 );
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
    h5{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
    }
`

export const MoreMessage = styled.div`
    margin-top: 20px;
    font-size: 12px;
    font-weight: 700;
    color: red;
    text-align: right;
`

