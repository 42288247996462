import {InfoListTableType} from "../../../types/common";

export const ContactData: InfoListTableType[] = [
    {
        title: '',
        subTitle: [],
        list: [
            {
                contents: [
                    { description: '팀맥스 주소', },
                    { description: 'team max dive resorts marbeach marigondon lapulapu', },
                ]
            },
            {
                contents: [
                    { description: '전화번호', },
                    { description: '+639152675836', },
                ]
            },
        ],
    }
]
