import styled from "styled-components";

import OpenWater from "../../../components/recreation/openwater";

const Wrap = styled.div`
`
const OpenWaterPage = () => {
    return(
        <Wrap>
            <OpenWater />
        </Wrap>
    )
}

export default OpenWaterPage;