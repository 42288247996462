import styled from "styled-components";
import media from "../../../../lib/media";

const Wrap = styled.div`

`

const Inner = styled.div`
`

const ContentBx = styled.div`
    font-size: 14px;
    line-height: 17px;
    white-space: pre-wrap;
    overflow: auto;
    max-width: 500px;
    max-height: 500px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    h2{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        box-sizing: border-box;
    }
    
    
    ${media.tablet`
        height: 400px;
    `};
`

interface Props {
    title: string;
    content: string;
}

const Agree = ({title, content}: Props) => {
    return (
        <Wrap>
            <Inner>
                <ContentBx>
                    <h2>{title}</h2>
                    {content}
                </ContentBx>
            </Inner>
        </Wrap>
    )
}

export default Agree;