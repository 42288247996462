import styled, {css} from "styled-components";


export const Wrap = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
`

export const Inner = styled.div`
    position: relative;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`

export const Container = styled.button`
    position: absolute;
    right: 10px;
    bottom: 10px;
`

export const ImgBx = styled.div<{$isShow: boolean}>`
    width: 80px;
    height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    
    ${( props ) => {
        return props.$isShow ? css`
            width: 320px;
            height: 394px;
            border-radius: 20px;

            img {
                width: 100%;
                height: auto;
            }
        ` : css`
            width: 80px;
            height: 80px;
            border-radius: 100%;

            img {
                width: 150px;
            }
        `;
    }}
    
    img{
        transition: width 0.2s ease-in-out;
    }
`

export const Message = styled.span<{$isShow: boolean}>`
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: ${ p => p.$isShow ? 0 : 1 };
`
