import MainContent from "../../components/main";

const MainPage = () => {
    return (
        <div>
            <MainContent/>
        </div>
    )
}

export default MainPage;