import * as S from "./styles"
import {DiverLessonType} from "../../../types/recreation";
import {InfoAddButton} from "./styles";
import {useInStaggerAction} from "../../../hooks/useInStaggerAction";
import {motion} from "framer-motion";

const DiverLesson = ({info}: { info: DiverLessonType }) => {
    const {
        ref,
        controls,
        variants,
        itemVariants
    } = useInStaggerAction({});

    return (
        <S.Wrap ref={ref}>
            <S.Inner
                initial="hidden"
                animate={controls}
                variants={variants}
            >
                <S.InfoBx>
                    <dl>
                        <motion.dt variants={itemVariants}>{info.title}</motion.dt>
                        <motion.dd variants={itemVariants}>{info.description}</motion.dd>
                    </dl>
                </S.InfoBx>
                <S.InfoListBx>
                    {
                        info.infoList.map((item, idx) => (
                            <S.InfoListItem
                                key={`diver-lesson-${idx}`}
                                variants={itemVariants}
                            >
                                {
                                    item.type === 'TABLE' && <>
                                        <h3>{item.title}</h3>
                                        {
                                            item.infoType?.map((v, sIdx) => (
                                                <dl key={`diver-lesson-info-${idx}-${sIdx}`}>
                                                    <dt>{v.title}</dt>
                                                    <dd>{v.description}</dd>
                                                </dl>
                                            ))
                                        }
                                    </>
                                }
                                {
                                    item.type === 'LIST' && <>
                                        <h3>{item.title}</h3>
                                        <ul>
                                            {
                                                item.infoList?.map((v, sIdx) => (
                                                    <li key={`diver-lesson-info-${idx}-${sIdx}`}>{v.description}</li>
                                                ))
                                            }
                                        </ul>
                                    </>
                                }
                                {
                                    item.type === 'ADD' && <>
                                        <h3>{item.title}</h3>
                                        <h4>{item.infoAdd?.description}</h4>
                                        {
                                            item.infoAdd?.items.map((v, sIdx) => (
                                                <InfoAddButton key={`diver-lesson-info-${idx}-${sIdx}`}
                                                               $isAdd={v.type === 'ADD'}>
                                                    <span>{v.type === 'DEFAULT' ? '-' : '+'}</span>{v.title}
                                                </InfoAddButton>
                                            ))
                                        }
                                    </>
                                }
                            </S.InfoListItem>
                        ))
                    }
                </S.InfoListBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default DiverLesson;