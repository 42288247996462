import {NoticeData} from "./data";
import NoticeItems from "./notice-items";
import * as S from "./styles"


const Notice = () => {
    return(
        <S.Wrap>
            <S.Inner>
                <NoticeItems title={"공지사항"} items={NoticeData}></NoticeItems>
                <NoticeItems title={"교육 & 펀다이빙 문의"} items={NoticeData}></NoticeItems>
            </S.Inner>
        </S.Wrap>
    )
}

export default Notice;