import Rescue from "../../../components/recreation/rescue";


const RescuePage = () => {
    return(
        <div>
            <Rescue></Rescue>
        </div>
    )
}

export default RescuePage;