import {DiverLessonAddContentType, DiverLessonType} from "../../../types/recreation";

export const MasterData: DiverLessonType = {
    title: '이 과정에서는 ...',
    description: '리더쉽(프로페셔널)로 가는 관문!\n마스터 스쿠버 다이버는 만 18세부터 시작할 수 있으며, 그룹 잠수, 활동의 계획, 잠수플랜, 조직통제와 관련된 기술, 잠수 장소의 분석, \n아마추어 다이버로써 최고의 권위가 주어집니다.',
    infoList: [
        {
            type: 'TABLE',
            title: '과정 참가자격 및 준비물',
            infoType: [
                {
                    title: '참가자격',
                    description: '18세',
                },
                {
                    title: '소요시간',
                    description: '5일',
                },
                {
                    title: '준비물',
                    description: '수영복, 개인세면도구, 증명사진 1부',
                },
                {
                    title: '비용',
                    description: '$1000',
                }],
        },
        {
            type: 'ADD',
            title: '교육 절차',
            infoAdd:
                {
                    items: [
                        {
                            title: '필수 다이빙',
                            type: 'DEFAULT',
                        },
                        {
                            title: '선택 다이빙',
                            type: 'ADD',
                        }]
                }
        }]
}

export const MasterDiverContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '공항픽업',
            description: ''
        },
        {
            title: '숙박5박',
            description: '(2일 1실)'
        },
        {
            title: '조식/중식',
            description: ''
        },
        {
            title: '교육',
            description: ''
        },
        {
            title: '라이센스',
            description: ''
        },
        {
            title: '장비렌탈',
            description: ''
        },
        {
            title: '이론강의',
            description: ''
        },
        {
            title: '수영장교육',
            description: ''
        },
        {
            title: '개방수역',
            description: '(다이빙)'
        },
    ],
    referenceList: [
        {
            title: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.',
        },
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
                link: '/',
            }
        }
    ]
}