import * as S from "./styles";
import {useCallback, useEffect, useState} from "react";
import {useCalculatorContext} from "../../../pages/expense/CalculatorPage";
import {CalculatorListType} from "../../../types/calculator";
import {GetMore} from "../../../api/calculator";

const MoreComponent = () => {
    const [moreListData, setMoreListData] = useState<CalculatorListType[]>();
    const [moreList, setMoreList] = useState<boolean[]>(
        Array.from({length: moreListData?.length || 0}, () => false)
    );

    const getData = async () => {
        const data = await GetMore();
        setMoreListData(data);
    }

    useEffect(() => {
        getData();
    }, []);

    const {setSchedule} = useCalculatorContext();

    const onChangeMan = useCallback((idx: number) => {
        const copy = moreList.concat();
        copy[idx] = !copy[idx];

        setMoreList(copy);
    }, [moreList]);

    useEffect(() => {
        if (moreListData) {
            const moreData: CalculatorListType[] = [];
            moreList.forEach((v, idx) => {
                if (v) {
                    moreData.push(moreListData[idx])
                    console.log(moreListData[idx]);
                }
            });

            setSchedule(
                moreData,
                "more",
            )
        }
    }, [moreList]);

    if (!moreListData) return null;

    return (
        <div>
            <S.MoreBx>
                <S.MoreListBx
                    initial={{y: 15, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{
                        duration: 0.2,
                        ease: 'easeOut',
                    }}
                >
                    {
                        moreListData.map((item, idx) => (
                            <S.CheckBx key={`more-${idx}`}>
                                <input
                                    id={`more-${idx}`}
                                    name={`more-${idx}`}
                                    type={'checkbox'}
                                    onChange={() => onChangeMan(idx)}
                                />

                                <label htmlFor={`more-${idx}`}>
                                    <div>
                                        {item.name}
                                        {
                                            item.price && <span>${item.price}</span>
                                        }
                                    </div>
                                </label>
                            </S.CheckBx>
                        ))
                    }
                    <S.MoreMessage>* 당일 기상상황 고려하여 진행이 불가능할 수 있습니다.</S.MoreMessage>
                </S.MoreListBx>
            </S.MoreBx>
        </div>
    )
}

export default MoreComponent;