import {ItemDataType} from "../../../types/main";

export const ItemData: ItemDataType[] = [
    {
        icon: '/icon/icon_about_00.svg',
        title: '빵빵한 무선 인터넷',
        description: '전 객실 Wifi를 통한 편리한\n무선인터넷 사용',
    },
    {
        icon: '/icon/icon_about_01.svg',
        title: '세부 다이빙 맛집',
        description: '내부 식사부터 도시락까지!\n순수 만드는 세부 맛집',
    },
    {
        icon: '/icon/icon_about_02.svg',
        title: '아름답고 넓은 수영장',
        description: '막탄 최고의 수영장에서\n교육과 수영을 동시에!',
    },
    {
        icon: '/icon/icon_about_03.svg',
        title: '편안한 휴식처',
        description: '전 객실 오션뷰, 풀사이드의\n편안한 휴식 공간',
    }
];