import {DiveAboutItemsType} from "../../../../types/about";

export const DiveAboutData: DiveAboutItemsType[] = [
    {
        src: '/icon/ic_dive_about_group.svg',
        title: 'NAUI SDI TDI 전문 교육 리조트',
        description: '아름다운 필리핀 세부 막탄섬 마리곤돈에 위치하고 있는 팀맥스 다이브 리조트는 세계 최초의 스쿠버다이빙 전문 교육단체이자 전통을 자랑합니다.',
    },
    {
        title: '전 객실 오션 & 풀사이드뷰',
        description: '전 객실 오션뷰 & 풀사이드뷰로, 세부 최대의 스쿠버다이빙 전용 교육풀을 보유하고 있습니다.',
    },
    {
        title: '리조트 & 샵 일체형 종합 다이빙 리조트',
        description: '팀맥스 다이브 리조트는 외부로 이동없이 교육, 숙박, 식사 등 모든 편의를 리조트 내에서 해결 가능합니다.',
    },
    {
        title: '철저하고 보수적인 교육',
        description: '초보부터 강사까지 모든 과정을 제공하여 여러분들을 최고의 다이버로 만들어 드립니다.',
    },
]