import * as S from "./styles";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {useCalculatorContext} from "../../index";
import {GetRental} from "../../../../../../api/calculator";
import {CalculatorListType} from "../../../../../../types/calculator";

interface Props {
    man: number;
    woman: number;
}

const RentalListBxContainer = ({children}: { children: ReactNode }) => {
    return (
        <S.RentalListBx
            initial={{y: 15, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{
                duration: 0.2,
                ease: 'easeOut',
            }}
        >
            {children}
        </S.RentalListBx>
    )
}

const getInitArray = (len: number, rentalData: CalculatorListType[]): boolean[][] =>
    Array.from({length: len}, () =>
        Array.from({length: rentalData.length}, () => false));

const RentalComponent = ({man, woman}: Props) => {
    const [rentalData, setRentalData] = useState<CalculatorListType[]>()
    const getData = async () => {
        const result = await GetRental();
        setRentalData(result);
    }

    useEffect(() => {
        getData();
    }, []);

    const {setSchedule} = useCalculatorContext();

    const [manList, setManList] = useState<boolean[][]>([]);
    const [womanList, setWomanList] = useState<boolean[][]>([]);

    const [manAllCheck, setManAllCheck] = useState<boolean[]>([]);
    const [manCancelCheck, setManCancelCheck] = useState<boolean[]>([]);

    const [womanAllCheck, setWomanAllCheck] = useState<boolean[]>([]);
    const [womanCancelCheck, setWomanCancelCheck] = useState<boolean[]>([]);

    useEffect(() => {
        if (rentalData) {
            setManList(getInitArray(man, rentalData));
            setManCancelCheck(Array.from({length: man}, () => false));
            setManAllCheck(Array.from({length: man}, () => false));

            console.log("========== MAN ===========");
            console.log(man, rentalData);
        }
    }, [man, rentalData]);

    useEffect(() => {
        if (rentalData) {
            setWomanList(getInitArray(woman, rentalData));
            setWomanCancelCheck(Array.from({length: woman}, () => false));
            setWomanAllCheck(Array.from({length: woman}, () => false));
        }
    }, [woman, rentalData]);

    const onChangeMan = useCallback((idx: number, sIdx: number) => {
        const manCopy = manList.concat();
        manCopy[idx][sIdx] = !manCopy[idx][sIdx];

        setManList(manCopy);

        const manAllCopy = manAllCheck.concat();
        manAllCopy[idx] = !manCopy[idx].includes(false);
        setManAllCheck(manAllCopy);

        const manCancelCopy = manCancelCheck.concat();
        manCancelCopy[idx] = false;
        setManCancelCheck(manCancelCopy);
    }, [manList]);

    const onChangeCancelMan = useCallback((idx: number, type: 'ALL_CHECK' | 'ALL_CANCEL') => {
        const manCopy = manList.concat();

        manCopy[idx].forEach((v, i) => {
            manCopy[idx][i] = type === 'ALL_CHECK';
        });

        setManList(manCopy);

        const manAllCopy = manAllCheck.concat();
        manAllCopy[idx] = type === 'ALL_CHECK';
        setManAllCheck(manAllCopy);

        const manCancelCopy = manCancelCheck.concat();
        manCancelCopy[idx] = type === 'ALL_CANCEL';

        setManCancelCheck(manCancelCopy);
    }, [manList]);


    const onChangeWoman = useCallback((idx: number, sIdx: number) => {
        const womanCopy = womanList.concat();
        womanCopy[idx][sIdx] = !womanCopy[idx][sIdx];

        setWomanList(womanCopy);

        const womanAllCopy = womanAllCheck.concat();
        womanAllCopy[idx] = !womanCopy[idx].includes(false);
        setWomanAllCheck(womanAllCopy);

        const womanCancelCopy = womanCancelCheck.concat();
        womanCancelCopy[idx] = false;
        setWomanCancelCheck(womanCancelCopy);
    }, [womanList]);

    const onChangeCancelWoman = useCallback((idx: number, type: 'ALL_CHECK' | 'ALL_CANCEL') => {
        const womanCopy = womanList.concat();

        womanCopy[idx].forEach((v, i) => {
            womanCopy[idx][i] = type === 'ALL_CHECK';
        });

        setWomanList(womanCopy);

        const womanAllCopy = womanAllCheck.concat();
        womanAllCopy[idx] = type === 'ALL_CHECK';
        setWomanAllCheck(womanAllCopy);

        const womanCancelCopy = womanCancelCheck.concat();
        womanCancelCopy[idx] = type === 'ALL_CANCEL';

        setWomanCancelCheck(womanCancelCopy);
    }, [womanList]);

    useEffect(() => {
        if (rentalData) {
            let data: any = {
                man: [],
                woman: [],
            };

            manList.forEach((v, idx) => {
                data.man[idx] = [];
                v.forEach((check, sIdx) => {
                    if (check) {
                        data.man[idx].push(rentalData[sIdx]);
                    }
                })
            });

            womanList.forEach((v, idx) => {
                data.woman[idx] = [];
                v.forEach((check, sIdx) => {
                    if (check) {
                        data.woman[idx].push(rentalData[sIdx]);
                    }
                })
            });

            setSchedule(data, 'rental');
        }
    }, [manList, womanList, rentalData]);

    if (!rentalData) return null;

    return (
        <S.Wrap>
            <S.ContentBx>
                <h4>- 남성 <span>({man}명)</span></h4>
                {
                    man > 0 && <S.RentalBx>
                        {
                            Array.from({length: man}).map((_, idx) => (
                                <RentalListBxContainer key={`man-${idx}`}>
                                    <h5>남성-{idx + 1}</h5>

                                    <div>
                                        <S.CheckBx key={`man-all-${idx}`}>
                                            <input
                                                id={`man-all-${idx}`}
                                                type={'checkbox'}
                                                onChange={() => onChangeCancelMan(idx, 'ALL_CHECK')}
                                                checked={manAllCheck[idx] || false}
                                            />

                                            <label htmlFor={`man-all-${idx}`}>
                                                <div>풀셋</div>
                                            </label>
                                        </S.CheckBx>

                                        {
                                            rentalData.map((item, sIdx) => (
                                                <S.CheckBx key={`man-${idx}-${sIdx}`}>
                                                    <input
                                                        id={`man-${idx}-${sIdx}`}
                                                        name={`man-${idx}`}
                                                        type={'checkbox'}
                                                        onChange={() => onChangeMan(idx, sIdx)}
                                                        checked={manList[idx] ? manList[idx][sIdx] : false}
                                                    />

                                                    <label htmlFor={`man-${idx}-${sIdx}`}>
                                                        <div>
                                                            {item.name}
                                                            {
                                                                item.price && <span>${item.price}</span>
                                                            }
                                                        </div>
                                                    </label>
                                                </S.CheckBx>
                                            ))
                                        }

                                        <S.CheckBx key={`man-cancel-${idx}`}>
                                            <input
                                                id={`man-cancel-${idx}`}
                                                type={'checkbox'}
                                                onChange={() => onChangeCancelMan(idx, 'ALL_CANCEL')}
                                                checked={manCancelCheck[idx] || false}
                                            />

                                            <label htmlFor={`man-cancel-${idx}`}>
                                                <div>없음</div>
                                            </label>
                                        </S.CheckBx>
                                    </div>
                                </RentalListBxContainer>
                            ))
                        }
                    </S.RentalBx>
                }
            </S.ContentBx>

            <S.ContentBx>
                <h4>- 여성 <span>({woman}명)</span></h4>
                {
                    woman > 0 && <S.RentalBx>
                        {
                            Array.from({length: woman}).map((_, idx) => (
                                <RentalListBxContainer key={`woman-${idx}`}>
                                    <h5>여성-{idx + 1}</h5>

                                    <div>
                                        <S.CheckBx key={`woman-all-${idx}`}>
                                            <input
                                                id={`woman-all-${idx}`}
                                                type={'checkbox'}
                                                onChange={() => onChangeCancelWoman(idx, 'ALL_CHECK')}
                                                checked={womanAllCheck[idx] || false}
                                            />

                                            <label htmlFor={`woman-all-${idx}`}>
                                                <div>풀셋</div>
                                            </label>
                                        </S.CheckBx>

                                        {
                                            rentalData.map((item, sIdx) => (
                                                <S.CheckBx key={`woman-${idx}-${sIdx}`}>
                                                    <input
                                                        id={`woman-${idx}-${sIdx}`}
                                                        name={`woman-${idx}`}
                                                        type={'checkbox'}
                                                        onChange={() => onChangeWoman(idx, sIdx)}
                                                        checked={womanList[idx] ? womanList[idx][sIdx] : false}
                                                    />

                                                    <label htmlFor={`woman-${idx}-${sIdx}`}>
                                                        <div>
                                                            {item.name}
                                                            {
                                                                item.price && <span>${item.price}</span>
                                                            }
                                                        </div>
                                                    </label>
                                                </S.CheckBx>
                                            ))
                                        }

                                        <S.CheckBx key={`woman-cancel-${idx}`}>
                                            <input
                                                id={`woman-cancel-${idx}`}
                                                type={'checkbox'}
                                                onChange={() => onChangeCancelWoman(idx, 'ALL_CANCEL')}
                                                checked={womanCancelCheck[idx] || false}
                                            />

                                            <label htmlFor={`woman-cancel-${idx}`}>
                                                <div>없음</div>
                                            </label>
                                        </S.CheckBx>
                                    </div>
                                </RentalListBxContainer>
                            ))
                        }
                    </S.RentalBx>
                }
            </S.ContentBx>
        </S.Wrap>
    )
}

export default RentalComponent;