import * as S from "./styles";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {CalculatorListType, CalculatorProcess} from "../../../../../../types/calculator";
import {useCalculatorContext} from "../../index";

const CheckBx = ({children}: { children: ReactNode }) => {
    return (
        <S.CheckBx
            initial={{x: -20, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            transition={{
                duration: 0.2,
                ease: 'easeOut',
            }}
        >
            {children}
        </S.CheckBx>
    )
}

const PackageList = ({calculatorList, packageName}: {
    calculatorList: CalculatorListType[],
    packageName: 'funDivingList' | 'technicalList'
}) => {
    const {setSchedule} = useCalculatorContext();

    const [option, setOption] = useState<CalculatorListType>();
    const [optionIdx, setOptionIdx] = useState<number>(-1);

    const [depth2, setDepth2] = useState<boolean[]>(
        Array.from({length: calculatorList.length}, () => false)
    );

    const [depth3, setDepth3] = useState<boolean[]>([]);

    const onChangeDepth2 = useCallback((idx: number) => {
        const copyDepth2 = depth2.concat().map((_) => false);
        copyDepth2[idx] = true;

        setDepth2(copyDepth2);

        setOption(calculatorList[idx]);
        setOptionIdx(idx);

        const depth3Arr: boolean[] = [];
        calculatorList[idx].optionList?.forEach((v, idx) => {
            depth3Arr[idx] = v.default;
        });

        setDepth3(depth3Arr);
    }, [depth2]);

    const onChangeDepth3 = useCallback((idx: number) => {
        const depth3Arr = depth3.concat();
        depth3Arr[idx] = !depth3Arr[idx];

        setDepth3(depth3Arr);
    }, [depth3]);

    useEffect(() => {
        if (option) {
            const isDepth2 = depth2.includes(true);
            const isDepth3 = depth3.includes(true);

            if (isDepth2 || isDepth3) {
                let data: CalculatorProcess = {
                    name: packageName,
                    process: {
                        id: 0,
                        name: '',
                        price: 0,
                    },
                    processList: []
                }

                depth2.forEach((v) => {
                    if (v) {
                        data.process = {
                            id: option.packageListId,
                            name: option.name,
                            price: option.price || 0,
                        }

                        option.optionList?.forEach((option: any, sIdx: number) => {
                            if (option.default || depth3[sIdx]) {
                                data.processList.push(option);
                            }
                        })
                    }
                });

                setSchedule(data, packageName);
            }
        }
    }, [depth2, depth3, option]);

    return (
        <S.ListBx>
            {
                calculatorList && <S.Bx>
                    <ul>
                        {
                            calculatorList.map((item, idx) => (
                                <CheckBx key={`category1-list-${packageName}-${idx}`}>
                                    <input
                                        id={`package-list-${packageName}-${idx}`}
                                        type={'radio'}
                                        name={packageName}
                                        onChange={() => onChangeDepth2(idx)}
                                    />

                                    <label htmlFor={`package-list-${packageName}-${idx}`}>
                                        <div>
                                            {item.name}
                                            {
                                                item.price && <span>${item.price}</span>
                                            }
                                        </div>

                                        {
                                            item.optionList && <img
                                                src={optionIdx === idx ? "/icon/ic_arrow_down_red.svg" : "/icon/ic_arrow_down.svg"}
                                                alt=""
                                            />
                                        }
                                    </label>
                                </CheckBx>
                            ))
                        }
                    </ul>
                </S.Bx>
            }

            {
                option && option.optionList && <S.Bx>
                    <ul>
                        {
                            option.optionList.map((item, idx) => (
                                <CheckBx key={`option-${packageName}-${optionIdx}-${idx}`}>
                                    <input
                                        id={`option-${packageName}-${optionIdx}-${idx}`}
                                        type={'checkbox'}
                                        defaultChecked={item.default}
                                        disabled={item.default}
                                        onChange={() => onChangeDepth3(idx)}
                                    />

                                    <label htmlFor={`option-${packageName}-${optionIdx}-${idx}`}>
                                        <div>
                                            {item.name}
                                            <span>${item.price}</span>
                                        </div>
                                    </label>
                                </CheckBx>
                            ))
                        }
                    </ul>
                </S.Bx>
            }
        </S.ListBx>
    )
}

export default PackageList;